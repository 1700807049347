import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from 'file-saver';
import { Moment } from 'moment';
import { BehaviorSubject, forkJoin, Subscription } from "rxjs";
import { finalize } from 'rxjs/operators';
import { CamsDefault } from 'src/app/shared/models/cams-default';
import { CertificationCompleted } from 'src/app/shared/models/certification-completed';
import { CertificationReasonChange } from 'src/app/shared/models/certification-reason-change';
import { CertificationValidate } from 'src/app/shared/models/certification-validate';
import { Deal } from 'src/app/shared/models/deal';
import { Incident } from 'src/app/shared/models/incident';
import { InterventionReason } from 'src/app/shared/models/intervention-reason';
import { OverhaulPlateUpdate } from 'src/app/shared/models/overhaul-plate-update';
import { StateJob } from 'src/app/shared/models/state-job';
import { TrailedMaterialStatus } from 'src/app/shared/models/trailed-material-status';
import { WagonMaintenance } from 'src/app/shared/models/wagon-maintenance';
import { CertificationService } from 'src/app/shared/services/certification/certification.service';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';
import { AgentService } from "../../../../core/services/agent/agent.service";
import { Anomaly } from "../../../models/anomaly";
import { MaintenancePlanUpdate } from "../../../models/maintenance-plan-update";
import { Service } from "../../../models/service";
import { WagonUpdate } from "../../../models/wagon-update";
import { WagonService } from "../../../services/wagon/wagon.service";
import { DialogCancelConfirmComponent } from '../../dialog-cancel-confirm/dialog-cancel-confirm.component';
import { TemplatePopupDialogComponent } from '../../template-popup-dialog/template-popup-dialog.component';
import { AresDialogComponent } from '../ares-dialog/ares-dialog.component';

const MY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT }
    , DatePipe]
})
export class CertificationComponent implements OnInit {

  @Input() public wagon: number;
  @Input() public pMex: string;
  @Input() public pInv: string;
  @Input() public wagonUpdate: WagonUpdate;
  @Input() public maintenancePlanList: MaintenancePlanUpdate[];
  @Input() public camFaultList: CamsDefault[];
  @Input() public stateJobs: StateJob[];
  @Output() changeTabEvent = new EventEmitter();
  @Output() validateCompletedEvent = new EventEmitter();
  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);

  anomalies: Anomaly[] = [];
  wagonMaintenance: WagonMaintenance;
  overhaulPlateUpdate: OverhaulPlateUpdate[];
  cams: CamsDefault[];
  services: Service[] = [];
  controlDisplayPage = false;
  initWarningMessages: string[]= [];
  initWarningMsg: string;

  certificationForm: FormGroup;
  incidents: FormArray;
  reforms: FormArray;
  affairNumber: FormControl;
  reason: FormControl;
  exitDate: FormControl;
  exitTime: FormControl;
  availability: FormControl;
  observations: FormControl;
  option1: FormControl;
  option2: FormControl;
  returnToServiceAdvice: FormControl;
  advice: boolean;
  cartridgeMaintenance: string;
  cartridgeATS: string;

  incidentColumns: string[] = ['incident', 'event', 'sheet', 'tandem', 'region', 'reasonType', 'endReason', 'viewed', 'amortization'];
  incidentDS: MatTableDataSource<FormGroup>;

  reformColumns: string[] = ['reform', 'event', 'model', 'type', 'station', 'reason', 'label', 'viewed', 'amortization'];
  reformDS: MatTableDataSource<FormGroup>;

  maintenanceColumns: string[] = ['group', 'organ', 'organLabel', 'work', 'defect', 'number', 'status'];
  maintenanceDS: MatTableDataSource<MaintenancePlanUpdate>;

  camColumns: string[] = ['cam', 'codes', 'labels', 'origin', 'status'];
  camDS: MatTableDataSource<CamsDefault>;
  
  flag_display_maint_plan = true;

  changeAvailability :boolean;

  listDeals: Deal[];
  listInterventionReason: InterventionReason[];
  listAvailabilities: TrailedMaterialStatus[];
  mAvisDispo: number;
  stateToDoColor: string;
  stateDoneColor: string;
  incidentsList: Anomaly[];
  currentInterventionReason: InterventionReason;
  isEnabledValidateBt: boolean;
  dateToday = new Date();
  isLoadingReport = false;
  isSavingAmortization = false
  incValids: boolean;
  refValids: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private wagonService: WagonService,
    private agentService: AgentService,
    private certificationService: CertificationService,
    private referenceService: ReferenceService
  ) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.incidents = this.formBuilder.array([]);
    this.reforms = this.formBuilder.array([]);
    this.affairNumber = this.formBuilder.control(null);
    this.reason = this.formBuilder.control(null , this.wagonUpdate.maintenanceOperation.isReasonMandatory ? Validators.required : undefined);
    this.exitDate = this.formBuilder.control(null, [Validators.required]);
    this.exitTime = this.formBuilder.control(null, [Validators.required]);
    this.availability = this.formBuilder.control(null, [Validators.required]);
    this.observations = this.formBuilder.control('');
    this.option1 = this.formBuilder.control('');
    this.option2 = this.formBuilder.control('');
    this.returnToServiceAdvice = this.formBuilder.control(false);
    this.certificationForm = this.formBuilder.group({
      incidents: this.incidents,
      reforms: this.reforms,
      observations: this.observations,
      affairNumber: this.affairNumber,
      reason: this.reason,
      exitDate: this.exitDate,
      exitTime: this.exitTime,
      availability: this.availability,
      option1: this.option1,
      option2: this.option2,
      returnToServiceAdvice: this.returnToServiceAdvice
    });

    this.controlDisplayCertification(this.wagon);
  }

  /**
  * Contrôle RI_MAJ_004_3 : Vérifier la validité de l'opération de maintenance 
  */
   controlDisplayCertification(idWagon: number): void {
    this.controlDisplayPage = false;
      this.subscriptionRefs.push(
        this.certificationService.controlAffichage(idWagon)
          .subscribe(wr => {
            this.controlDisplayPage = wr.data;
            if (this.controlDisplayPage) {
              this.checkSpecificUpdate(this.wagon);
            } else {
              this.loadingSubject.next(false);
              this.isEnabledValidateBt = false;
            }         
          })
      );
    
  }

  /**
   * Contrôle RI_MAJ_004_2 : Une mise à jour spécifique a été faite pendant la période de mise à jour [ouverture ; certification]
   * Contrôle RI_MAJ_004_1: Si au moins une nouvelle anomalie est apparue depuis l'ouverture à la mise à jour
   */
  checkSpecificUpdate(idWagon: number): void {
      this.subscriptionRefs.push(
        this.certificationService.controlOpeMaintenanceToDisplayPage(idWagon)
          .subscribe(wr => {
            this.initWarningMessages = wr.data;
            
            this.initWarningMsg = '';

            // warnings declarations
            const majspec_cond_expl = this.translateService.instant('wagon-update.certification.warning_messages.msg_operating_conditions');
            const majspec_type_aut_ofWork = this.translateService.instant('wagon-update.certification.warning_messages.msg_work_auth');
            const majspec_stateOfUse = this.translateService.instant('wagon-update.certification.warning_messages.msg_stateOfUse');
            const majspec_chassis = this.translateService.instant('wagon-update.certification.warning_messages.msg_reconstitution_chassis');
            const msg_newAnomaly = this.translateService.instant('wagon-update.certification.warning_messages.msg_newAnomaly');
            const msg_maint_ope_MAG = this.translateService.instant('wagon-update.certification.warning_messages.msg_no_maintenance_plan');

            this.initWarningMessages.forEach(element => {
              if (element == "MAJSPEC_01"){
                this.initWarningMsg = this.initWarningMsg.concat(majspec_cond_expl).concat("\n");
              } else if (element == "MAJSPEC_02"){
                this.initWarningMsg = this.initWarningMsg.concat(majspec_type_aut_ofWork).concat("\n");
              } else if (element == "MAJSPEC_03"){
                this.initWarningMsg = this.initWarningMsg.concat(majspec_stateOfUse).concat("\n");
              } else if (element == "MAJSPEC_04"){
                this.initWarningMsg = this.initWarningMsg.concat(majspec_chassis).concat("\n");
               }else if (element == "warn_ano"){
                this.initWarningMsg = this.initWarningMsg.concat(msg_newAnomaly).concat("\n");
              } else if (element == "no_maint_plan"){
                this.initWarningMsg = this.initWarningMsg.concat(msg_maint_ope_MAG).concat("\n");
                this.flag_display_maint_plan = false;
              }
            });
            this.loadInitDatas();
            
          })
      );
  }

  /**
  * Methode pour init datas
  */
  private loadInitDatas() {
      this.subscriptionRefs.push(
        forkJoin(
          this.wagonService.getAnomalies(this.wagon),
          this.agentService.getServices(),
          this.certificationService.getDealsList(this.wagon),
          this.certificationService.getInterventionReasonList(this.wagon, this.wagonUpdate.mex),
          this.certificationService.getAvailabilitiesList(this.wagonUpdate.maintenanceOperation.code),
          this.certificationService.findReturnToServiceAdvice(this.wagon, this.wagonUpdate.mex),
          this.wagonService.getWagonMaintenanceByMex(this.wagonUpdate.mex),
          this.referenceService.findOverhaulPlateUpdate(this.wagon)
        ).subscribe(([an, se, dl, ir, av, dispo, wm, op]) => {
          this.anomalies = an.data.filter(e => !e.isWroteOff || e.isUpdated);
          this.services = se.data;
          this.maintenanceDS = new MatTableDataSource<MaintenancePlanUpdate>(this.maintenancePlanList);
          this.listDeals = dl.data;
          this.listInterventionReason = ir.data;
          this.listAvailabilities = av.data;
          if (this.listAvailabilities) {
            this.listAvailabilities.forEach(t => t['codeLabel'] = t.code + ' - ' + t.label);
          }
          this.mAvisDispo = dispo.data;
          this.wagonMaintenance = wm.data;
          this.overhaulPlateUpdate = op.data;
          this.cams =this.camFaultList;

          this.camDS = new MatTableDataSource<CamsDefault>(this.cams);

          this.resetDatas();
          this.loadingSubject.next(false);
        })
      );
  }

  changeValueAvailability(event): void {
    if (event === 'XN') {
      this.certificationForm.get('observations').setValidators(Validators.required);
    } else {
      this.certificationForm.get('observations').clearValidators();
    }
    this.certificationForm.get('observations').updateValueAndValidity();
    this.changeAvailability = false;
  }

  onSubmit(): void {
    this.isEnabledValidateBt = false;
    this.changeAvailability = true;
    if (!this.checkMandatoryFields()) {
      const title = this.translateService.instant('wagon-update.certification.title');
      const message = this.translateService.instant('wagon-update.certification.validation_error');
      this.showErrorPopUpDialog(title, message);
      this.isEnabledValidateBt = true;
      return;
    }
    const msgDataControl = this.checkDataControls();
    if (msgDataControl) {
      const title = this.translateService.instant('wagon-update.certification.title');
      const message = msgDataControl;
      this.showErrorPopUpDialog(title, message);
      this.isEnabledValidateBt = true;
      return;
    }

    const certificationValidate = new CertificationValidate();
    certificationValidate.wagonId = this.wagon;
    certificationValidate.exitDate = this.exitDate.value;
    certificationValidate.exitTime = this.exitTime.value;
    certificationValidate.availabilityCode = this.availability.value;
    if (this.reason.value) {
      certificationValidate.reasonCode = this.reason.value.code;
    }
    if (this.affairNumber.value) {
      certificationValidate.dealCode = this.affairNumber.value.id;
    }
    certificationValidate.observations = this.observations.value;
    certificationValidate.option1 = this.option1.value;
    certificationValidate.option2 = this.option2.value;
    certificationValidate.adviceARES = this.returnToServiceAdvice.value;
    certificationValidate.mexToCertify = this.wagonUpdate.mex;
    this.isSavingAmortization = true;
    this.certificationService.validate(certificationValidate)
      .subscribe((cert) => {
        this.isSavingAmortization = false;
        if (cert && cert.data && cert.data.messageError) {
          const title = this.translateService.instant('wagon-update.certification.title');
          this.showErrorPopUpDialog(title, cert.data.messageError.message);
          this.isEnabledValidateBt = true;
        } else {
          this.showPopARES(cert.data);
        }
    });
  }

  /**
   * Contrôle des informations obligatoires
   */
  private checkMandatoryFields(): boolean {
    let valid = true;
    if (!this.exitDate.value) {
      this.exitDate.setErrors(Validators.required);
      valid = false;
    }
    if (!this.exitTime.value) {
      this.exitTime.setErrors(Validators.required);
      valid = false;
    }
    if (!this.availability.value) {
      this.availability.setErrors(Validators.required);
      valid = false;
    }
    if (this.wagonUpdate.maintenanceOperation.isReasonMandatory && !this.reason.value) {
      this.reason.setErrors(Validators.required);
      valid = false;
    }

    return valid;
  }

  /**
     * Contrôle des informations obligatoires
     */
  private checkDataControls(): string {
    // Date de sortie
    const exitDateForm = new Date(this.exitDate.value);
    const exitTimeForm = this.exitTime.value;
    exitDateForm.setHours(exitTimeForm.split(":")[0]);
    exitDateForm.setMinutes(exitTimeForm.split(":")[1]);
    const currentDate = new Date();
    currentDate.setHours(23);
    currentDate.setMinutes(59);
    if (exitDateForm > currentDate) {
      return this.translateService.instant('wagon-update.certification.RG_MAJ_004_4');
    }
    
    if (this.wagonUpdate.dateDispo) {
      const dateDispo = new Date(this.wagonUpdate.dateDispo);
      if (dateDispo > exitDateForm) {
        return this.translateService.instant('wagon-update.certification.RG_MAJ_004_5');
      }
    }

    if (this.wagonUpdate.entry) {
      const dateEntry = new Date(this.wagonUpdate.entry);
      if (dateEntry > exitDateForm) {
        return this.translateService.instant('wagon-update.certification.RG_MAJ_004_22');
      }
    }
    
    // Observation
    const txtObservation = this.observations.value;
    const availabilityCode = this.availability.value;
    if (availabilityCode === "XN" && !txtObservation) {
      return this.translateService.instant('wagon-update.certification.RG_MAJ_004_20');
    }

    if (txtObservation && txtObservation.includes(";")) {
      return this.translateService.instant('wagon-update.certification.RG_MAJ_004_21');
    }

    return "";
  }  

  print(): void {
    this.isLoadingReport = true;
    this.subscriptionRefs.push(
      this.certificationService.downloadCertificationReport(this.wagon)
        .pipe(
          finalize(() => this.isLoadingReport = false)
        )
        .subscribe(
          (report) => {
            saveAs(report.blob, report.filename);
        })
    );
  }

  /**
   * Reset les donnes
   */
  private resetDatas() {
    this.incidents.clear();
    this.incidentsList = [];
    this.reforms.clear();

    this.anomalies.forEach(anomalie => {
      if ('INCIDENT' === anomalie.type) {
        this.incidents.push(
          this.addIncident(anomalie)
        );
        this.incidentsList.push(anomalie);
      } else if ('REFORME' === anomalie.type) {
        this.reforms.push(
          this.addReform(anomalie)
        );
      }
    });
    this.incValids = true;;
    for (const iterator of this.incidents.value) {
      if (!iterator.amortization) {
        this.incValids = false;
        break
      }
    }
    this.refValids = true;;
    for (const iterator of this.reforms.value) {
      if (!iterator.amortization) {
        this.refValids = false;
        break
      }
    }
    this.incidentDS = new MatTableDataSource<FormGroup>(this.incidents.controls as FormGroup[]);
    this.reformDS = new MatTableDataSource<FormGroup>(this.reforms.controls as FormGroup[]);

    
    this.listDeals.forEach(d => {
      if (d.id !== 0) {
        d['label'] = d.id + " " + d.object;
      }
    });
    if (this.listDeals.length === 1) {
      this.affairNumber.setValue(this.listDeals[0]);
    }
    // MARGO-319
    if (this.wagonUpdate.dealId) {
      this.affairNumber.disable();
    }

    this.reason.setValue(this.wagonUpdate.interventionReason);
    this.currentInterventionReason = this.wagonUpdate.interventionReason;

    const serviceOCW = this.services.find(s => s.code === "OCW");
    if (!serviceOCW) {
      this.exitDate.setValue(new Date());
      this.exitTime.setValue(this.getTime(new Date));
    }

    if (this.wagonUpdate) {
      this.observations.setValue(this.wagonUpdate.observation);
      this.option1.setValue(this.wagonUpdate.option1);
      this.option2.setValue(this.wagonUpdate.option2);
    } else {
      this.observations.setValue('');
      this.option1.setValue('');
      this.option2.setValue('');
    }

    this.generateCartridges();

    this.calculateServiceAdvice();

    this.stateToDoColor = (this.stateJobs.filter(s => s.code === "1"))[0].color;
    this.stateDoneColor = (this.stateJobs.filter(s => s.code === "4"))[0].color;

    this.changeAvailability = false;
    this.isEnabledValidateBt = true;
  }

  private addIncident(anomalie: Anomaly) {
    const amortizationValue = this.getAmortizationValue(anomalie);
    const vuValue = this.getViewValue(anomalie);

    return this.formBuilder.group({
      id: this.formBuilder.control(anomalie.id),
      incident: this.formBuilder.control(this.getAnomalieNumber(anomalie.year,  anomalie.number)),
      event: this.formBuilder.control(anomalie.event),
      sheet: this.formBuilder.control(anomalie.incident ? anomalie.incident.eventSheetNumber : null),
      tandem: this.formBuilder.control(anomalie.incident ? anomalie.incident.originDomain + ' ' + anomalie.incident.originPseudoKART : null),
      region: this.formBuilder.control(anomalie.incident && anomalie.incident.region ? anomalie.incident.region.label : null),
      type: this.formBuilder.control(anomalie.incident ? anomalie.incident.originType : null),
      endReason: this.formBuilder.control(anomalie.incident ? anomalie.incident.endReason : null, [Validators.required]),
      viewed: this.formBuilder.control(vuValue),
      amortization: this.formBuilder.control(amortizationValue)
    });
  }

  private addReform(anomalie: Anomaly) {
    const amortizationValue = this.getAmortizationValue(anomalie);
    const vuValue = this.getViewValue(anomalie);

    let modelLabel = anomalie.reform ? anomalie.reform.model.code : '';
    modelLabel = (anomalie.reform && anomalie.reform.complementTagModel) ? modelLabel + ' ' + anomalie.reform.complementTagModel : modelLabel;

    return this.formBuilder.group({
      id: this.formBuilder.control(anomalie.id),
      reform: this.formBuilder.control(this.getAnomalieNumber(anomalie.year,  anomalie.number)),
      event: this.formBuilder.control(anomalie.event),
      model: this.formBuilder.control(modelLabel),
      type: this.formBuilder.control(anomalie.reform && anomalie.reform.type ? anomalie.reform.type.code : null),
      typeLabel: this.formBuilder.control(anomalie.reform && anomalie.reform.type ? anomalie.reform.type.label : null),
      station: this.formBuilder.control(anomalie.reform && anomalie.reform.detectStation ? anomalie.reform.detectStation.code : null),
      stationLabel: this.formBuilder.control(anomalie.reform && anomalie.reform.detectStation ? anomalie.reform.detectStation.label : null),
      reason: this.formBuilder.control(anomalie.reform && anomalie.reform.reason ? anomalie.reform.reason.code + ' ' + anomalie.reform.reason.label : null),
      label: this.formBuilder.control(anomalie.reform ? anomalie.reform.label : null),
      viewed: this.formBuilder.control(vuValue),
      amortization: this.formBuilder.control(amortizationValue)
    });
  }

  private getAnomalieNumber(anoYear: string, anoNumber: number): string {
    let newAnoNumber = anoNumber.toString().substr(0, 8);
    while (newAnoNumber.length < 8) {
      newAnoNumber = '0' + newAnoNumber;
    }
    newAnoNumber = anoYear + ' ' + newAnoNumber;
    return newAnoNumber;
  }

  /**
   * Return Amortization value 
   * @param anomalie 
   * @returns 
   */
  private getAmortizationValue(anomalie: Anomaly): boolean {
  
    let amortizationValue = false;
    if (anomalie.isViewed) {
      amortizationValue = anomalie.isWroteOff;
    }
    return amortizationValue;
  }

  /**
   * Return View value 
   * @param anomalie 
   * @returns 
   */
  private getViewValue(anomalie: Anomaly): boolean {
    return anomalie.isViewed;
  }

  private setAmortizationValue(anomalie: Anomaly, amortizationValue: boolean, vuValue: boolean): void {
    anomalie.isViewed = vuValue;
    anomalie.isWroteOff = amortizationValue;
  }

  compareWithDeal = (d1: Deal, d2: Deal):boolean =>  {
    return d1 && d2 && d1.id === d2.id;
  }

  compareWithInterventionReason = (i1: InterventionReason, i2: InterventionReason):boolean =>  {
    return i1 && i2 && i1.code === i2.code;
  }

  /** Get hour and minute of a date */
  private getTime(date: Date): string{
    return date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute:'2-digit'
    });
  }

  /**
   * Get the date of DatePicker control
   * @param ctrl 
   */
  getDateFromCtrl(ctrl: AbstractControl): string | Date {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : new Date(ctrl.value);
  }

  private generateCartridges(): void {

    if (this.overhaulPlateUpdate) {
      const plAB = this.overhaulPlateUpdate.find(pl => pl.type === 'AB');
      const plSD = this.overhaulPlateUpdate.find(pl => pl.type === 'SD');
      let plateList = plAB;
      if (plSD && plSD.overhaulNature && 
        ((plSD.overhaulNature.code === "D" && plSD.origin > plAB.origin) || 
        plSD.overhaulNature.code === "S" || plSD.overhaulNature.code === "SUR")) {
          plateList = plSD;
      }

      if (plateList) {
        const pipe = new DatePipe('en-US'); // Use your own locale
        this.cartridgeMaintenance = plateList.frequency.code;
        this.cartridgeMaintenance += " REV ";
        this.cartridgeMaintenance += plateList.changeWorkshop ? plateList.changeWorkshop.initials : null;
        this.cartridgeMaintenance += " ";
        this.cartridgeMaintenance += pipe.transform(plateList.actual, 'dd/MM/yyyy');
        this.cartridgeMaintenance += " ";
        
        if (plSD && plSD.overhaulNature.code === "D" && plSD.origin > plAB.origin) {
          plateList = plSD;
        } else {
          plateList = plAB;
        }

        this.cartridgeMaintenance += (plateList.rank < 10 ? "0" + plateList.rank : plateList.rank.toString());
        this.cartridgeMaintenance += " ";
        this.cartridgeMaintenance += (plateList.overhaulNature ? plateList.overhaulNature.code : null);
        this.cartridgeMaintenance += " ";
        this.cartridgeMaintenance += plateList.origin ? new Date(plateList.origin).getFullYear() : "";
      }
    }

    if (this.wagonMaintenance && this.wagonMaintenance.otherSystematicWorkUpdate) {
      const pipe = new DatePipe('en-US'); // Use your own locale
      this.cartridgeATS = this.wagonMaintenance.otherSystematicWorkUpdate.frequency + " " + "ATS" + " " +
        (this.wagonMaintenance.otherSystematicWorkUpdate.workshop.initials ? 
            this.wagonMaintenance.otherSystematicWorkUpdate.workshop.initials : this.wagonMaintenance.otherSystematicWorkUpdate.workshop.code) + " " +
            pipe.transform(this.wagonMaintenance.otherSystematicWorkUpdate.datetime, 'dd/MM/yyyy');
    }
  }

  private calculateServiceAdvice(): void {
    if (this.mAvisDispo === 0) {
      this.returnToServiceAdvice.disable();
    } else {
      // Cas où l'avis de remise en service sera initialisé suite à certification
      // Vérifier si une préparation ARES a été validée
      if (this.wagonUpdate.isValidated) {
        this.returnToServiceAdvice.setValue(true);
      } else {
        this.returnToServiceAdvice.setValue(this.agentService.getAgentData().value.company.code === '58002' ? true : false);
      }

    }
  }

  /**
   * RG_MAJ_004_1
   * @param event 
   * @param id 
   */
  amortizationIncidentChange( event:MatCheckboxChange, element:any): void {
    this.isSavingAmortization = true;
    // Get the indicident with the given id
    const id = element.get('id').value;
    const vuValue = event.checked ? true : element.get('viewed').value;
    if (element.get('viewed').value !== vuValue) {
      (element.get('viewed') as FormControl).setValue(vuValue, {emitEvent:false, onlySelf: true,emitModelToViewChange: false, emitViewToModelChange: false});
    }
    const incId = this.incidents.controls.filter(i => i.value.id === id );
    const incSourceId = this.incidentsList.filter(i => i.id === id );
    if (incId && incId.length > 0) {
      const inc = incId[0];
      const incSource = incSourceId[0];
      if (!inc.value.endReason || inc.value.endReason === "") {
        // RG_MAJ_004_ 24
        element.get('amortization').setValue(false);

        const title = this.translateService.instant('wagon-update.certification.incidents');
        const message = this.translateService.instant('wagon-update.certification.error-incident-amortization');
        this.showErrorPopUpDialog(title, message);
        this.isSavingAmortization = false;
      } else {
        this.setAmortizationValue(incSource, event.checked, vuValue);
        this.saveAmortization(id, event.checked, vuValue,  inc.value.endReason);
      }
    }
  }

  /**
   * View Change
   * @param vuValue 
   * @param newAmortizationValue 
   * @param id 
   */
  viewChange(event:MatCheckboxChange, element:any): void {
    this.isSavingAmortization = true;
    const id = element.get('id').value;
    if (event.checked){
      element.get('viewed').setValue(true);
    }else{
      element.get('viewed').setValue(false);
      // RG_MAJ_004_ 24
      element.get('amortization').setValue(false);
    }
    const newAmortizationValue = element.get('amortization').value; 
    this.saveAmortization(id, newAmortizationValue, event.checked, null);
  }

  /**
   * Amortization Change
   * @param vuValue 
   * @param newAmortizationValue 
   * @param id 
   */
  amortizationChange(event:MatCheckboxChange , element: any): void {
    const vuValue = event.checked ? true : element.get('viewed').value; 
    if (element.get('viewed').value !== vuValue) {
      (element.get('viewed') as FormControl).setValue(vuValue, {emitEvent:false, onlySelf: true,emitModelToViewChange: false, emitViewToModelChange: false});
    }
    this.isSavingAmortization = true;
    this.saveAmortization( element.get('id').value, event.checked, vuValue,null);
  }

  saveAmortization(idAnomaly: number, newAmortizationValue: boolean, vuValue: boolean, newEndReason: string): void {
    const newAnomaly = new Anomaly();
    newAnomaly.id = idAnomaly;
    this.setAmortizationValue(newAnomaly, newAmortizationValue, vuValue);
    // Préventivement pour éviter qu'une précédente certification ait mis le booléen certif à 1
    // pour une réforme vue, non amortie mais amortie cette fois (ouverture dans un 2ème établissement).
    newAnomaly.isCertified = false;
    if (newEndReason) {
      newAnomaly.incident = new Incident();
      newAnomaly.incident.endReason = newEndReason;
    }
    this.subscriptionRefs.push(
      this.certificationService.saveAmortization(idAnomaly, newAnomaly)
        .subscribe(res => {
          if (!res) {
            const title = this.translateService.instant('wagon-update.certification.title');
            const message = this.translateService.instant('wagon-update.certification.error-save-amortization');
            this.showErrorPopUpDialog(title, message);
          }
          this.incValids = true;
          for (const iterator of this.incidents.value) {
            if (!iterator.amortization) {
              this.incValids = false;
              break;
            }
          }
          this.refValids = true;
          for (const iterator of this.reforms.value) {
            if (!iterator.amortization) {
              this.refValids = false;
              break;
            }
          }
          this.isSavingAmortization = false;
        })
    );
  }

  /**
   * Method to show an error Dialog
   */
   showErrorPopUpDialog(title: string, message: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '12.5rem';
    dialogConfig.minWidth = '13.75rem';
    dialogConfig.data = {
      namePopUp: 'alert_msg',
      titlePopUp: title,
      msgPopUp: message
    };
    this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
  }

  checkReasonChange(): void {
    // Vérifier si une expertise a été prise en compte à l'ouverture du wagon 
    if (this.wagonUpdate && this.wagonUpdate.expId) {
      // Enregistrer le nouveau motif en base
      this.saveReason(this.wagon, this.reason.value.code, null, false);
    } else {
      this.showMaintenancePlanChangePopUpDialog();
    }
  }

  showMaintenancePlanChangePopUpDialog(): void {
    const dialogRef = this.dialog.open(DialogCancelConfirmComponent, {
      data: {
        title: 'wagon-update.certification.maintenance_change',
        message: 'wagon-update.certification.maintenance_change_message',
        cancelLabel: 'wagon.button_dialog.no',
        confirmLabel: 'wagon.button_dialog.yes',
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === "") {
        // Si l'utilisateur clique sur Annuler (croix) alors réinitialisation du champ " Motif "
        this.reason.setValue(this.currentInterventionReason);
      } else if (result == false) {
        this.saveReason(this.wagon, this.reason.value.code, this.observations.value, false)
      } else {
        this.saveReason(this.wagon, this.reason.value.code, this.observations.value, true)
      }
    });
  }

  saveReason(idWagon: number, code: string, observations: string, reset: boolean): void {
    const reasonChange = new CertificationReasonChange();
    reasonChange.code = code;
    reasonChange.observations = observations;
    reasonChange.reset = reset;
    this.subscriptionRefs.push(
      this.certificationService.saveReason(idWagon, reasonChange)
        .subscribe(res => {
          if (res) {
            this.currentInterventionReason = this.reason.value;
            if (reset) {
              this.changeTabEvent.emit(2);
            }
          }
        })
    );
  }

  /**
   * Fermeture de la mise à jour
   */
  showPopARES(cert: CertificationCompleted): void {
    const dialogRef = this.dialog.open(AresDialogComponent, {
      data: {
        wagonId: this.wagon,
        mex: this.wagonUpdate.mex,
        certificationCompleted: cert
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(() => {
      this.wagonService.setWagonWorkDone(this.wagon);
      this.validateCompletedEvent.emit();
    });
  }
}
