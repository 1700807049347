import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MaskService } from 'ngx-mask';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { Expertise } from 'src/app/shared/models/expertise';
import { InterventionReason } from 'src/app/shared/models/intervention-reason';
import { MaintenanceOperation } from 'src/app/shared/models/maintenance-operation';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';

@Component({
  selector: 'app-modify-expertise-dialog',
  templateUrl: './modify-expertise-dialog.component.html',
  styleUrls: ['./modify-expertise-dialog.component.scss'],
  providers: [MaskService]
})
export class ModifyExpertiseDialogComponent {

  /** langage du navigateur */
  lang: string;
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Physical wagon ID */
  currentWagonId: number;
  /** Wagon MEX */
  currentMex: string;
  /** Wagon MEX to display */
  currentMexMask: string;
  /** Expertise to modify */
  expertise: Expertise;
  /** Le wagon est sous contrat de maintenance  */
  isLevel34: boolean;
  /** MaintenanceOperation selected */
  maintenanceOperationSelected: MaintenanceOperation;
  /** Maintenance Operation list */
  maintenanceOperationList: MaintenanceOperation[];
  /** InterventionReason selected */
  patternSelected: InterventionReason;
  /** La liste des motifs */
  patternList: InterventionReason[];
  /** Selected element in the maintenance operation list */
  modelOperationList: string;
  /** Selected element in the reason list */
  modelPatternList: string;
  /** Flag to control activation/desactivation of the Motif select */
  reasonEnabled: boolean;
  /** Flag to control activation/desactivation of the Validate button */
  btnValiderEnabled: boolean;
  
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private maskService: MaskService,
    private referenceService: ReferenceService,
    private utilityService: UtilityService) {

    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
    this.currentMex = data.mex;
    this.isLevel34 = data.isLevel34;
    this.currentWagonId = data.currentWagonId;
    this.expertise = new Expertise();
    this.expertise.maintenanceOperation = data.expertise.maintenanceOperation;
    this.expertise.interventionReason = data.expertise.interventionReason;
    this.expertise.track = data.expertise.track;
    this.expertise.position = data.expertise.position;
    this.expertise.observation = data.expertise.observation;
    // RI-P2-CU1-IHM03-002
    this.btnValiderEnabled = true;
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.currentMexMask = this.maskService.applyMask(this.currentMex, '00 00 0000 000 0');
    this.initMaintenanceOperationList();
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
  }

  /**
   * Maintenance Operation selection event
   */
   onMaintenanceOperationSelection(): void {
    if (this.maintenanceOperationList) {
      const opeMaintList = this.maintenanceOperationList.filter(mOp => mOp['codeLabel'] === this.modelOperationList);
      this.maintenanceOperationSelected = opeMaintList[0];
      this.patternSelected = null;
      this.modelPatternList = null;
      if (this.maintenanceOperationSelected && this.maintenanceOperationSelected.code
        && this.maintenanceOperationSelected.isReasonMandatory) {
        // RG-P1-CU2-IHM01-007
        this.reasonEnabled = true;
        // RG-P1-CU2-IHM01-009
        this.btnValiderEnabled = false;
      } else {
        // RG-P1-CU2-IHM01-006
        this.reasonEnabled = false;
        // RG-P1-CU2-IHM01-009
        this.btnValiderEnabled = !!this.maintenanceOperationSelected;
      }
    }
  }

  onPatternSelection(): void {
    if (this.patternList) {
      const patList = this.patternList.filter(mOp => mOp['codeLabel'] === this.modelPatternList);
      this.patternSelected = patList[0];
      this.btnValiderEnabled = !!this.patternSelected;
    }
  }

  /**
   * Initialiser la liste des opérations de maintenance 
   */
  initMaintenanceOperationList(): void {
    const pageable = new Pageable();
    pageable.sort = 'code';
    const wagonId = this.currentWagonId !== 0 ? this.currentWagonId : undefined;
    this.subscriptionRefs.push(
      this.referenceService.getMaintenanceOperation(wagonId, undefined, undefined, pageable)
        .subscribe(mOpe => {
          this.maintenanceOperationList = mOpe.data;
          this.maintenanceOperationList.forEach(op => op['codeLabel']=op.code + ' : ' + op.label);
          // Si l’utilisateur a déjà selectionné les valeurs, les récupérer
          this.initMaintenanceOperationValue();
          this.initInterventionReasonList();
        })
    );
  }

  /**
   * Initialiser la liste des motifs
   */
  initInterventionReasonList(): void {
    const pageable = new Pageable();
    pageable.sort = 'code';
    pageable.order = 'asc';
    if (this.isLevel34) {
      this.subscriptionRefs.push(
        this.referenceService.getInterventionsReason(this.currentMex, '10', pageable)
          .subscribe(interventionReason => {
            this.patternList = interventionReason.data;
            this.patternList.forEach(rs => rs['codeLabel']=rs.code + ' : ' + rs.label);
            // Si l’utilisateur a déjà selectionné les valeurs, les récupérer
            this.initInterventionReasonValue();
            this.loadingSubject.next(false);
        })
    );
    } else {
      this.subscriptionRefs.push(
        this.referenceService.getAllInterventionsReason(pageable)
          .subscribe(interventionReason => {
            this.patternList = interventionReason.data;
            this.patternList.forEach(rs => rs['codeLabel']=rs.code + ' : ' + rs.label);
            // Si l’utilisateur a déjà selectionné les valeurs, les récupérer
            this.initInterventionReasonValue();
            this.loadingSubject.next(false);
          })
      );
    }
  }

  initMaintenanceOperationValue(): void {
    const expertiseOpeMaintCode = this.expertise.maintenanceOperation ? this.expertise.maintenanceOperation.code : null;
    const opeMaint = this.maintenanceOperationList.filter(mOp => mOp.code === expertiseOpeMaintCode);
    this.maintenanceOperationSelected = opeMaint[0];
    if (this.maintenanceOperationSelected) {
      this.modelOperationList = this.maintenanceOperationSelected['codeLabel'];
      if (!this.maintenanceOperationSelected.isReasonMandatory) {
        this.reasonEnabled = false;
        this.btnValiderEnabled = true;
      }
    }
  }

  initInterventionReasonValue(): void {
    if (this.expertise.interventionReason && this.expertise.interventionReason.code) {
      const patList = this.patternList.filter(mOp => mOp.code === this.expertise.interventionReason.code);
      this.patternSelected = patList[0];
      this.modelPatternList = this.patternSelected['codeLabel'];
      this.reasonEnabled = true;
      this.btnValiderEnabled = true;
    }
  }

  /**
   * Method to submit the form
   */
  onSubmit(): void {
    const expertise = new Expertise();
    expertise.maintenanceOperation = this.maintenanceOperationSelected;
    expertise.interventionReason = this.patternSelected;
    expertise.track = this.expertise.track;
    expertise.position = this.expertise.position;
    expertise.observation = this.expertise.observation;
    this.utilityService.setModifiedExpertise(expertise);
    this.dialog.closeAll();
  }
}
