import { MaintenanceAidType } from './maintenance-aid-type';
/** API model:Table:  T_CAM   */
export class MaintenanceAid {
  /** CAM_CODE_PK VARCHAR2(6 BYTE) */
  code: string;
  /** type.code = CAM_TYPCAM_CODE_FK VARCHAR2(10 BYTE) */
  type: MaintenanceAidType;
  /** CAM_LIB VARCHAR2(360 BYTE) */
  label: string;
  /** CAM_FLAG_ORGANE NUMBER(1,0) */
  isComponentAid: boolean;
}
