import { Component, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActionBarConfig } from '../action-bar/action-bar-config';
import { BarConfigService } from '../../services/bar-config/bar-config.service';
import { AuthService } from '../../services/auth/auth.service';
import { AgentService } from '../../services/agent/agent.service';

/**
 * Json node data with nested structure.
 * Each node has a pagename,  an url, a list of children and can have a specified data name
 */
export class SiteNode {
  children: SiteNode[];
  pagename: string;
  url: string;
  data: string;
  fp?: string | string[];
  oneFpIn?: string[];
  service?: string ;
}
/**
 * The Json tree data in string. The data could be parsed into Json object
 */
const TREE_DATA = JSON.stringify({
  'core.mast_nav.home': {url: '/home'},
  'core.mast_nav.consultation.title': {
    children: {
      'wagon.title': {url: '/consultation/wagons', fp: ['TBR01', 'ECON01']},
      'expertise.title': {
        url: '/consultation/expertises',
        children: {
          'expertise.detail.title': {url: '/consultation/expertises/?', data: 'core.sitemap.expertise_detail_data'}
        },
        fp: ['TBR01', 'DWE01']
      },
      'axle.title_not_loaded': {url: '/consultation/axle', fp: ['CES01']},
      'vpi.title': {url: '/consultation/vpi', fp: ['VPI01']},
      'customer_space.title': {url: '/consultation/customer-space', fp: ['ECON01']},
      'tabco.title': {url: '/consultation/tabco', fp: ['TBR01']}
    },
    oneFpIn: ['ECON01', 'DWE01', 'CES01', 'VPI01', 'TBR01']
  },
  'core.mast_nav.update.title': {
    children: {
      'deal.title': {url: '/update/deals', oneFpIn: ['PAF001', 'PAF002', 'PAF003', 'PAF004']},
      'recommissioning.title': {url: '/update/recommissioning', oneFpIn: ['DWA02', 'DWA03', 'DWA04', 'DWA05']},
      'wagon.wagon_plan_title': {url: '/update/wagons', oneFpIn: ['MWR01']},
      'expertise-wagon.title-plan': {url: '/update/expertise', fp: ['TBR01','DWE02']},
      'vpi.title': {url: '/update/vpi', oneFpIn: ['VPI02', 'VPI03']},
      'axle.creation_title': {url: '/update/axles', oneFpIn: ['CRE01', 'ATE01', 'OTE01', 'MES01', 'REE01']},
      'anomaly.title' : { url: '/update/anomalies',  oneFpIn: ['CIN01', 'CRF01', 'MRF01', 'MIN02']},
      'review_report.title' : { url: '/update/review-reports',  fp: ['SPVR01']},
      'visit.title' : { url: '/update/visits',  oneFpIn: ['DWV01', 'SVE01']},
      'billing.title' : { url: '/update/billing',  oneFpIn: ['FAC001', 'FAC002']}
    },
    oneFpIn: ['DWA02', 'DWA03', 'DWA04', 'DWA05', 'PAF001', 'PAF002', 'PAF003', 'PAF004',
      'VPI02', 'VPI03', 'CRE01', 'ATE01', 'OTE01', 'MES01', 'REE01', 'MWR01', 'SPVR01', 'DWV01', 'SVE01']
  },
  'core.mast_nav.admin.title': {
    children: {
      'core.mast_nav.admin.scheduling.tasks_list': {url: '/administration/scheduling/search/tasks', fp: 'PLA01'},
      'core.mast_nav.admin.scheduling.restart_tasks': {url: '/administration/scheduling/restart', fp: 'PLA01'},
      'core.mast_nav.admin.cleareances.title': {url: '/administration/cleareances/search/users', fp: 'HAB01'},
      'core.mast_nav.admin.pc_messages': {url: '/administration/pc-messages', fp: 'MGN05'},
      'core.mast_nav.admin.specific_updates': {url: '/administration/specific-updates', oneFpIn: ['GWT01', 'ETU01', 'ETU02', 'SCE01', 'SCE02', 'SCE03', 'WAP01', 'SCO02', 'SCC01']}
    },
    service: 'PC'
  },
  'core.sitemap.title': {url: '/sitemap'},
  'core.about.title': {url: '/about'},
  'core.faq.title': {url: '/faq'}
});

/**
 * Site database, it can build a tree structured Json object from string.
 * Each node in Json object represents a site page.
 * The input will be a json object string, and the output is a list of `SiteNode` with nested
 * structure.
 */
@Injectable()
export class SiteDatabase {
  /** Subject to observe changes in nested node's collapse/open states */
  dataChange = new BehaviorSubject<SiteNode[]>([]);
  /** dataChange value getter  */
  get data(): SiteNode[] { return this.dataChange.value; }
  /**
   * SiteDatabase constructor
   */
  constructor() {
    this.initialize();
  }
  /** Init database */
  initialize() {
    // Parse the string to json object.
    const dataObject = JSON.parse(TREE_DATA);

    // Build the tree nodes from Json object. The result is a list of `SiteNode` with nested
    //     site node as children.
    const data = this.buildSiteTree(dataObject, 0);
    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `FileNode`.
   */
  buildSiteTree(obj: {[key: string]: any}, level: number): SiteNode[] {
    return Object.keys(obj).reduce<SiteNode[]>((accumulator, key) => {
      const value = obj[key];

      const node = new SiteNode();
      node.pagename = key;
      node.url = value.url;
      if (value.service !== undefined) {
        node.service = value.service;
      }
      if (value.fp !== undefined) {
        node.fp = value.fp;
      }
      if (value.oneFpIn !== undefined) {
        node.oneFpIn = value.oneFpIn;
      }
      if (value.children) {
        node.children = this.buildSiteTree(value.children, level + 1);
      }
      if (value.data) {
        node.data = value.data;
      }

      return accumulator.concat(node);

    }, []);
  }
}

/**
 * `SiteMapComponent` display tree of the application structure
 */
@Component({
  selector: 'app-site-map',
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss'],
  providers: [SiteDatabase]
})
export class SiteMapComponent {
  /** Cdk tree controller  */
  nestedTreeControl: NestedTreeControl<SiteNode>;
  /** Material data source for nested tree. */
  nestedDataSource: MatTreeNestedDataSource<SiteNode>;
  /** Action bar configuration */
  actionBarConfig: ActionBarConfig = {
    sectionTitle: 'core.sitemap.title',
    hasBreadcrumb: false
  };
  /**
   * SiteMapComponent constructor
   * @param database Inject tree database
   * @param barConfigService Inject service to configure Action bar & Control bar
   * @param authService Inject application's authentication service
   * @param agentService Inject application's agent service
   */
  constructor(database: SiteDatabase,
              private barConfigService: BarConfigService,
              public authService: AuthService,
              public agentService: AgentService) {
    this.barConfigService.sendActionBarConfig(this.actionBarConfig);
    this.nestedTreeControl = new NestedTreeControl<SiteNode>(this.getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();

    database.dataChange.subscribe(data => this.nestedDataSource.data = data);
  }
  hasNestedChild = (_: number, nodeData: SiteNode) => nodeData.children !== undefined;
  private getChildren = (node: SiteNode) => node.children;

  /** mock function to perform a change detection cycle on template input event */
  mockFunction() {
  }
}
