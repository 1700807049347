import { TemplatePortal } from '@angular/cdk/portal';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { ApiResponse } from '../../../core/services/app-api/api-response.model';
import { AppApiService } from '../../../core/services/app-api/app-api.service';
import { Anomaly } from '../../models/anomaly';
import { CommercialContractDetail } from '../../models/commercial-contract-detail';
import { DamageReport } from '../../models/damage-report';
import { Deal } from '../../models/deal';
import { Expertise } from '../../models/expertise';
import { ExploitationHistory } from '../../models/exploitation-history';
import { InitUpdateSpace } from '../../models/init-update-space';
import { InterventionHistory } from '../../models/intervention-history';
import { Intervention2History } from '../../models/intervention2-history';
import { LogicalWagon } from '../../models/logical-wagon';
import { Media } from '../../models/media';
import { PoseHistory } from '../../models/pose-history';
import { Recommissioning } from '../../models/recommissioning';
import { Wagon } from '../../models/wagon';
import { WagonAxle } from '../../models/wagon-axle';
import { WagonAxleType } from '../../models/wagon-axle-type';
import { WagonBogie } from '../../models/wagon-bogie';
import { WagonBogieType } from '../../models/wagon-bogie-type';
import { WagonBox } from '../../models/wagon-box';
import { WagonBoxType } from '../../models/wagon-box-type';
import { WagonBrakeCylinder } from '../../models/wagon-brake-cylinder';
import { WagonBrakeMainOrgan } from '../../models/wagon-brake-main-organ';
import { WagonBrakeParticularity } from '../../models/wagon-brake-particularity';
import { WagonBumpOrgan } from '../../models/wagon-bump-organ';
import { WagonDrawHook } from '../../models/wagon-draw-hook';
import { WagonDrawSpring } from '../../models/wagon-draw-spring';
import { WagonEmptyLoadedDevice } from '../../models/wagon-empty-loaded-device';
import { WagonFrameEnd } from '../../models/wagon-frame-end';
import { WagonMaintenance } from '../../models/wagon-maintenance';
import { WagonRegulator } from '../../models/wagon-regulator';
import { WagonSuspension } from '../../models/wagon-suspension';
import { WagonTechnicalData } from '../../models/wagon-technical-data';
import { WagonTechnicalDataDetail } from '../../models/wagon-technical-data-detail';
import { WagonTransport } from '../../models/wagon-transport';
import { WagonUpdate } from '../../models/wagon-update';
import { WagonUpdateGcu } from '../../models/wagon-update-gcu';
import { WagonUpdateHistory } from '../../models/wagon-update-history';
import { WagonUpdateR } from '../../models/wagon-updateR';
import { WagonWheel } from '../../models/wagon-wheel';
import { WagonWheelType } from '../../models/wagon-wheel-type';
import { WagonProduction } from '../../models/wagon-production';
import { DealWorkshop } from '../../models/deal-workshop';
import { Moment } from 'moment';
import { MexHistory } from '../../models/mex-history';
import { DealWagonUpdate } from '../../models/deal-wagon-update';
import { CustomHttpParamEncoder } from '../custom-http-param-encoder';
import { InventoryHistory } from '../../models/inventory-history';

/**
 * `WagonService` is responsible for retrieve wagon related datas through API calls.
 *  It make also bridge between Components to manage WagonComponent
 */
@Injectable({
  providedIn: 'root'
})
export class WagonService {
  /** Subject for wagon change monitoring */
  private wagonIdSubject = new BehaviorSubject<number>(undefined);
  /** Subject for wagon display change monitoring */
  private displayStatusSubject = new BehaviorSubject<boolean>(false);
  /** Subject for wagon portal change monitoring */
  private wagonTemplates = new BehaviorSubject<TemplatePortal<any>[]>(null);
  /** Subject for wagon work change monitoring */
  private wagonWorkDone = new BehaviorSubject<number>(-1);
  /** current deal infos */
  private dealInfos: {id: number, interveningWorkshopList: DealWorkshop[], multiple?: any, currentWagonId?: number} = {id: null, interveningWorkshopList: []};

  public currentMex: string;
  public currentWagon: number;

  private sharedSubject = new BehaviorSubject<{wagon: Wagon, technicals: WagonTechnicalData[], logicalWagon: LogicalWagon, logicals: LogicalWagon[]}>(undefined);

  /**
   * WagonService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) { }

  /**
   * Set current wagon's id
   * @param id the wagon's id
   */
  public setCurrentWagonId(id: number): void {
    this.wagonIdSubject.next(id);
    this.sharedSubject.next({wagon: null, technicals: null, logicalWagon: null, logicals: null});
  }
  /**
   * Retrieve current wagon's id
   * @returns wagon's id as `BehaviorSubject`
   */
  public getCurrentWagonId(): BehaviorSubject<number> {
    return this.wagonIdSubject;
  }
  /**
   * Set wagon datas
   */
  public setSharedDatas(wagon: Wagon, technicals: WagonTechnicalData[], logicalWagon: LogicalWagon, logicals?: LogicalWagon[]): void {
    this.sharedSubject.next({wagon, technicals, logicalWagon, logicals});
  }

  /**
   * Retrieve wagon datas
   * @returns current wagon as `BehaviorSubject`
   */
  public getSharedDatas(): BehaviorSubject<{wagon: Wagon, technicals: WagonTechnicalData[], logicalWagon: LogicalWagon, logicals: LogicalWagon[]}> {
    return this.sharedSubject;
  }

  /**
   * Set wagon work done
   */
   public setWagonWorkDone(id: number): void {
    this.wagonWorkDone.next(id);
  }

  /**
   * Retrieve wagon work done
   * @returns current work done as `BehaviorSubject`
   */
  public getWagonWorkDone(): BehaviorSubject<number> {
    return this.wagonWorkDone;
  }
  /**
   * Set current deal datas
   */
  public setDealInfos(id: number, interveningWorkshopList: DealWorkshop[], multiple?: any, currentWagonId?: number): void {
    this.dealInfos.id = id;
    this.dealInfos.interveningWorkshopList = interveningWorkshopList;
    this.dealInfos.multiple = multiple;
    this.dealInfos.currentWagonId = currentWagonId;
  }

  /**
   * Retrieve wagon work done
   * @returns current work done as `BehaviorSubject`
   */
  public getDealInfos(): {id: number, interveningWorkshopList: DealWorkshop[], multiple?: any, currentWagonId?: number} {
    return this.dealInfos;
}

  /**
   * Set current wagon component display status
   * @param show the wagon's display status
   */
  public setDisplayStatus(show: boolean): void {
    this.displayStatusSubject.next(show);
  }
  /**
   * Retrieve current wagon component display status
   * @returns wagon's display status as `Observable`
   */
  public getDisplayStatus(): Observable<boolean> {
    return this.displayStatusSubject.asObservable();
  }
  /**
   * Set current wagon component page specifics element
   * @param templates templates portals to inject
   */
  public setCurrentPortalTemplates(templates: TemplatePortal<any>[]): void {
    this.wagonTemplates.next(templates);
  }
  /**
   * Retrieve current page specifics element to inject
   * @returns templates portals as `Observable`
   */
  public getCurrentPortalTemplates(): Observable<TemplatePortal<any>[]> {
    return this.wagonTemplates.asObservable();
  }
  /**
   * Retrieve given id wagon's update space
   * @param id the wagon's id
   * @returns encapsulated wagon update datas as `Observable`
   */
  public getWagonUpdate(id: number): Observable<ApiResponse<WagonUpdate>> {
    return this.api.get<ApiResponse<WagonUpdate>>(`/wagons/${id}/update`)
    .pipe(
      catchError(this.handleError<any>('getWagonUpdate', {data: null}))
    );
  }

  /**
   * Retrieve given id wagon's update space avec la restriction d'etablissement habilité
   * @param id the wagon's id
   * @returns encapsulated wagon update datas as `Observable`
   */
   public getWagonUpdateEtabHab(id: number): Observable<ApiResponse<WagonUpdate>> {
    return this.api.get<ApiResponse<WagonUpdate>>(`/wagons/${id}/update-etab-hab`)
    .pipe(
      catchError(this.handleError<any>('getWagonUpdateEtabHab', {data: null}))
    );
  }

  /**
   * Retrieve wagon with given id
   * @param id the wagon's id
   * @returns encapsulated wagon datas as `Observable`
   */
  public getWagon(id: number): Observable<ApiResponse<Wagon>> {
    return this.api.get<ApiResponse<Wagon>>(`/wagons/${id}`)
    .pipe(
      catchError(this.handleError<any>('getWagon', {data: null}))
    );
  }

  /**
   * Retrieve logical wagon of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated array of logical wagon datas as `Observable`
   */
  public getLogicalWagons(id: number): Observable<ApiResponse<LogicalWagon[]>> {
    return this.api.get<ApiResponse<LogicalWagon[]>>(`/wagons/${id}/logical-wagons`)
    .pipe(
      catchError(this.handleError<any>('getLogicalWagon', {data: []}))
    );
  }

  /**
   * Retrieve logical wagon of wagon with given mex
   * @param mex the wagon's mex
   * @returns encapsulated array of logical wagon datas as `Observable`
   */
  public getLogicalWagonsForMex(mex: string, inventoryCode?: string): Observable<ApiResponse<LogicalWagon[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    if (inventoryCode !== undefined) {
      parameters = parameters.set('inventory', `${inventoryCode}`);
    }
    return this.api.get<ApiResponse<LogicalWagon[]>>(`/logical-wagons`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getLogicalWagonsForMex', {data: []}))
    );
  }
  public getLogicalWagonsForExMex(mex: string): Observable<ApiResponse<LogicalWagon[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('exmex', `1`);
    return this.api.get<ApiResponse<LogicalWagon[]>>(`/logical-wagons`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getLogicalWagonsForExMex', {data: []}))
    );
  }

  /**
   * Retrieve logical wagon of wagon with given mex
   * @param mex the wagon's mex
   * @returns encapsulated array of logical wagon datas as `Observable`
   */
  public getLogicalWagonsForMexStartsWith(mex: string, pageable?: Pageable): Observable<ApiResponse<LogicalWagon[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      parameters = pageable.build();
      parameters.keys().forEach(key => {
        parameters = parameters.set(key, parameters.get(key) );
      });
    }
    parameters = parameters.set('mex', `${mex}`);
    return this.api.get<ApiResponse<LogicalWagon[]>>(`/logical-wagons/search`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getLogicalWagonsForMexStartsWith', {data: []}))
    );
  }
  public getLogicalWagonsForExMexStartsWith(mex: string, pageable?: Pageable): Observable<ApiResponse<LogicalWagon[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      parameters = pageable.build();
      parameters.keys().forEach(key => {
        parameters = parameters.set(key, parameters.get(key) );
      });
    }
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('exmex', `1`);
    return this.api.get<ApiResponse<LogicalWagon[]>>(`/logical-wagons/search`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getLogicalWagonsForExMexStartsWith', {data: []}))
    );
  }

  /**
   * check if logical wagon exists with given mex
   * @param mex the wagon's mex
   * @returns encapsulated boolean as `Observable`
   */
  public getLogicalWagonsExistsForMex(mex: string, inventoryCode?: string): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    if (inventoryCode !== undefined) {
      parameters = parameters.set('inventory', `${inventoryCode}`);
    }
    return this.api.get<ApiResponse<boolean>>(`/logical-wagons/exists`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getLogicalWagonsExistsForMex', {data: null}))
    );
  }

  /**
   * Retrieve technical datas of wagon with given id
   * @param id the wagon's id
   * @param invCode the wagon's inventory
   * @returns encapsulated array of technical datas as `Observable`
   */
  public getWagonTechnicalDatas(id: number, inventoryCode?: string): Observable<ApiResponse<WagonTechnicalData[]>> {
    let parameters: HttpParams = new HttpParams();
    if (inventoryCode !== undefined) {
      parameters = parameters.set('inventory', `${inventoryCode}`);
    }
    return this.api.get<ApiResponse<WagonTechnicalData[]>>(`/wagons/${id}/technical-datas`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonTechnicalDatas', {data: []}))
    );
  }

  /**
   * Retrieve mex history of wagon with given mex
   * @param mex the wagon's mex
   * @returns encapsulated array of mex history as `Observable`
   */
  getMexHistory(mex: string): Observable<ApiResponse<MexHistory[]>> {
    return this.api.get<ApiResponse<MexHistory[]>>(`/wagons/mex-histories/${mex}`)
    .pipe(
      catchError(this.handleError<any>('getMexHistory', {data: []}))
    );
  }

  /**
   * Search the list of open and / or current cases attached to the wagon for the intervening establishment (completed by default)
   * @param mex wagon's mex
   * @param workshop worshop's code
   * @param done is deal done
   */
  public getDealRelatedWagonPage(mex: string, workshop?: string, done?: boolean, pageable?: Pageable): Observable<ApiResponse<Deal[]>> {
    let parameters: HttpParams = new HttpParams();
    if (workshop !== undefined) {
      parameters = parameters.set('workshop', `${workshop}`);
    }
    if (done !== undefined) {
      parameters = parameters.set('done', `${done}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<Deal[]>>(`/wagons/${mex}/deals`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getDealRelatedWagonPage', {data: []}))
    );
  }

  /**
   * check the date of the next revision N
   * @param mex wagon's mex
   * @param inventory wagon inventory
   * @returns encapsulated boolean element as `Observable`
   */
  public isRevisionNplanned(mex: string, inventory?: string): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    if (inventory !== undefined) {
      parameters = parameters.set('inventory', `${inventory}`);
    }

    return this.api.get<ApiResponse<boolean>>(`/wagons/${mex}/next-revision`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('isRevisionNplanned', {data: []}))
    );
  }


  /**
   * Obtenir la liste des expertises d'un wagon physique
   * @param id the wagon's id
   * @param isClose the wagon is close
   * @returns encapsulated array of expertise datas as `Observable`
   */
  public getWagonExpertise(id: number, isClose?: boolean): Observable<ApiResponse<Expertise[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isClose !== undefined) {
      parameters = parameters.set('isClose', `${isClose}`);
    }
    return this.api.get<ApiResponse<Expertise[]>>(`/wagons/${id}/expertises`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonExpertise', {data: []}))
    );
  }

  /**
   * Retrieve detailed technical datas of wagon with given id
   * @param id the wagon's id
   * @param inventory the wagon's inventory
   * @returns encapsulated array of detailed technical datas as `Observable`
   */
  public getWagonTechnicalDatasDetails(id: number, inventoryCode?: string): Observable<ApiResponse<WagonTechnicalDataDetail[]>> {
    let parameters: HttpParams = new HttpParams();
    if (inventoryCode !== undefined) {
      parameters = parameters.set('inventory', `${inventoryCode}`);
    }
    return this.api.get<ApiResponse<WagonTechnicalDataDetail[]>>(`/wagons/${id}/technical-datas/detail`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonTechnicalDatasDetails', {data: []}))
    );
  }

  /**
   * Retrieve transport datas of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated transport datas as `Observable`
   */
  public getWagonTransport(id: number): Observable<ApiResponse<WagonTransport>> {
    return this.api.get<ApiResponse<WagonTransport>>(`/wagons/${id}/transport`)
    .pipe(
      catchError(this.handleError<any>('getWagonTransport', {data: null}))
    );
  }

  /**
   * Retrieve lvl2 intervention history datas of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated array of lvl2 intervention datas as `Observable`
   */
  public getIntervention2History(id: number): Observable<ApiResponse<Intervention2History[]>> {
    return this.api.get<ApiResponse<Intervention2History[]>>(`/wagons/${id}/lvl2-interventions`)
    .pipe(
      catchError(this.handleError<any>('getIntervention2History', {data: []}))
    );
  }

  /**
   * Retrieve maintenance datas of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated maintenance datas as `Observable`
   */
  public getWagonMaintenance(id: number): Observable<ApiResponse<WagonMaintenance>> {
    return this.api.get<ApiResponse<WagonMaintenance>>(`/wagons/${id}/maintenance`)
    .pipe(
      catchError(this.handleError<any>('getWagonMaintenance', {data: null}))
    );
  }

  /**
   * Retrieve maintenance datas of wagon with given mex
   * @param id the wagon's mex
   * @returns encapsulated maintenance datas as `Observable`
   */
   public getWagonMaintenanceByMex(mex: string): Observable<ApiResponse<WagonMaintenance>> {
    return this.api.get<ApiResponse<WagonMaintenance>>(`/wagons/${mex}/maintenance/mex`)
    .pipe(
      catchError(this.handleError<any>('getWagonMaintenanceByMex', {data: null}))
    );
  }

  /**
   * Check the validity of the reference wagon with given id, current Mex  and mex
   * @param id the wagon's id
   * @param mex the mex's wagon
   * @returns encapsulated boolean datas as `Observable`
   */
  public isValidReferenceWagon(id: number, mex: string, currentMex: string): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    if (currentMex !== undefined) {
      parameters = parameters.set('curmex', `${currentMex}`);
    }
    if (mex !== undefined) {
      parameters = parameters.set('mex', `${mex}`);
    }
    return this.api.get<ApiResponse<boolean>>(`/wagons/${id}/reference-wagon`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('isValidReferenceWagon', {data: null}))
    );
  }

  /**
   * Retrieve intervention history datas of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated array of intervention datas as `Observable`
   */
  public getInterventionHistory(id: number): Observable<ApiResponse<InterventionHistory[]>> {
     return this.api.get<ApiResponse<InterventionHistory[]>>(`/wagons/${id}/interventions`)
    .pipe(
      catchError(this.handleError<any>('getInterventionHistory', {data: []}))
    );
  }

  /**
   * Retrieve intervention notices history datas of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated array of notice intervention datas as `Observable`
   */
  public getInterventionNoticeHistory(id: number): Observable<ApiResponse<any[]>> {
    return this.api.get<ApiResponse<any[]>>(`/wagons/${id}/intervention-notices`)
    .pipe(
      catchError(this.handleError<any>('getInterventionNoticeHistory', {data: []}))
    );
  }

  /**
   * Retrieve intervention datas of intervention with given id
   * @param id the intervention's id
   * @returns encapsulated intervention datas as `Observable`
   */
   public getIntervention(id: number): Observable<ApiResponse<InterventionHistory>> {
    return this.api.get<ApiResponse<InterventionHistory>>(`/interventions/${id}`)
    .pipe(
      catchError(this.handleError<any>('getIntervention', {data: null}))
    );
  }
  /**
   * Retrieve intervention datas of lvl 2 intervention with given id
   * @param id the intervention's id
   * @returns encapsulated intervention datas as `Observable`
   */
  public getIntervention2(id: number): Observable<ApiResponse<Intervention2History>> {
    return this.api.get<ApiResponse<Intervention2History>>(`/interventions/lvl-2/${id}`)
    .pipe(
      catchError(this.handleError<any>('getIntervention2', {data: null}))
    );
  }

  /**
   * Retrieve anomalies datas of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated array of intervention datas as `Observable`
   */
  public getAnomalies(id: number): Observable<ApiResponse<Anomaly[]>> {
    return this.api.get<ApiResponse<Anomaly[]>>(`/wagons/${id}/anomalies`)
    .pipe(
      catchError(this.handleError<any>('getAnomalies', {data: []}))
    );
  }

  /**
   * Obtain the less recent anomaly of a physical wagon since an undamped reform
   * @param id the wagon's id
   * @returns encapsulated array of intervention datas as `Observable`
   */
  public findAnomalyUndepreciatedReform(id: number): Observable<ApiResponse<Anomaly>> {
    return this.api.get<ApiResponse<Anomaly>>(`/wagons/${id}/anomalie-undepreciated-reform`)
    .pipe(
      catchError(this.handleError<any>('findAnomalyUndepreciatedReform', {data: []}))
    );
  }

  /**
   * Retrieve Exploitation history datas of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated exploitation datas as `Observable`
   */
  public getExploitationHistory(id: number): Observable<ApiResponse<ExploitationHistory>> {
    return this.api.get<ApiResponse<ExploitationHistory>>(`/wagons/${id}/exploitation`)
    .pipe(
      catchError(this.handleError<any>('getExploitationHistory', {data: null}))
    );
  }

  /**
   * Retrieve damage report datas of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated array of damage report datas as `Observable`
   */
  public getDamageReports(id: number, pageable?: Pageable): Observable<ApiResponse<DamageReport[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<DamageReport[]>>(`/wagons/${id}/wdr`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getDamageReports', {data: []}))
    );
  }

  /**
   * Retrieve recommissioning datas of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated recommissioning datas as `Observable`
   */
  public getRecommissioning(id: number): Observable<ApiResponse<Recommissioning>> {
    return this.api.get<ApiResponse<Recommissioning>>(`/wagons/${id}/recommissioning`)
    .pipe(
      catchError(this.handleError<any>('getRecommissioning', {data: null}))
    );
  }

  /**
   * Retrieve commercial contract details of contract with given code
   * @param code the contract's code
   * @returns encapsulated array of Commercial Contract Detail datas as `Observable`
   */
  public getCommercialContractDetails(code: string): Observable<ApiResponse<CommercialContractDetail[]>> {
    return this.api.get<ApiResponse<CommercialContractDetail[]>>(`/commercial-contracts/${code}/details`)
    .pipe(
      catchError(this.handleError<any>('getDamageReports', {data: []}))
    );
  }

  /**
   * Retrieve axle pose history of wagon with given id
   * @param id the wagon's id
   * @returns encapsulated array of pose history  datas as `Observable`
   */
   public getWagonPoseHistoryList(id: number): Observable<ApiResponse<PoseHistory[]>> {
    return this.api.get<ApiResponse<PoseHistory[]>>(`/wagons/${id}/pose-history`)
    .pipe(
      catchError(this.handleError<any>('getWagonPoseHistoryList', {data: []}))
    );
  }

  /**
   * update wagon update record
   * @param element axle element datas
   */
  public updateWagonUpdate(id: number, element: WagonUpdate): Observable<Response>  {
    return this.api.put<Response>(`/wagons/${id}/update`, element, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateWagonUpdate', null))
    );
  }

  updateWtAuthorization(id: number, formData: FormData): Observable<Response>  {
    return this.api.put<Response>(`/wagons/${id}/work-time-authorizations`, formData, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateWtAuthorization', null))
    );
  }

  updateMaintenanceContract(id: number, formData: FormData): Observable<Response>  {
    return this.api.put<Response>(`/wagons/${id}/maintenance-contract`, formData, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateMaintenanceContract', null))
    );
  }
  updateCommercialContract(id: number, formData: FormData): Observable<Response>  {
    return this.api.put<Response>(`/wagons/${id}/commercial-contract`, formData, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateCommercialContract', null))
    );
  }

  updateInventory(id: number, formData: FormData): Observable<Response>  {
    return this.api.put<Response>(`/wagons/${id}/inventory`, formData, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateInventory', null))
    );
  }
  
  updateCondex(id: number, techData: WagonTechnicalData): Observable<Response>  {
    return this.api.put<Response>(`/wagons/${id}/condex`, techData, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateCondex', null))
    );
  }
  
  updateNiv2(id: number, wagon: Wagon): Observable<Response>  {
    return this.api.put<Response>(`/wagons/${id}/niv2`, wagon, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateNiv2', null))
    );
  }
  /**
   * update stage of wagon update record
   * @param element axle element datas
   */
  public updateStageWagonUpdate(id: number, stage: number): Observable<Response>  {
    return this.api.put<Response>(`/wagons/${id}/update-stage-wagon`, stage, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateStageWagonUpdate', null))
    );
  }

  /**
   * Find defect code with max class defect
   * @param id the wagon's id
   */
  public getMaxClassDefectCode(id: number): Observable<ApiResponse<string>>  {
    return this.api.get<ApiResponse<CommercialContractDetail[]>>(`/wagons/${id}/anomalies/max-class-defect-code`)
    .pipe(
      catchError(this.handleError<any>('getMaxClassDefectCode', {data: []}))
    );
  }

  /**
   * create wagon update history record
   * @param element wagon update history datas
   */
  public createWagonUpdateHistory(id: number, element: WagonUpdateHistory): Observable<ApiResponse<WagonUpdateHistory>>  {
    return this.api.post<ApiResponse<WagonUpdateHistory>>(`/wagons/${id}/update/history`, element)
    .pipe(
      catchError(this.handleError<any>('createWagonUpdateHistory', null))
    );
  }

  public saveVisit(visit: any): Observable<void> {
    return this.api.post<void>(`/wagons/visits`, visit)
    .pipe(
      catchError(this.handleError<any>('saveVisit', null))
    );
  }
  /**
   * delete wagon bogie records
   * @param wagonId wagon id
   * @param inventory inventory code
   */
  public deleteWagonBogies(mex: string, inventory: string): Observable<{}>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.delete<{}>(`/wagons/bogies`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('deleteWagonBogies', null))
    );
  }

  /**
   * delete  wagon bogie type records
   * @param wagonId wagon id
   * @param inventory inventory code
   */
  public deleteWagonBogieTypes(mex: string, inventory: string): Observable<{}>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.delete<{}>(`/wagons/bogie-types`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('deleteWagonBogieTypes', null))
    );
  }

  /**
   * delete wagon box records
   * @param wagonId wagon id
   * @param inventory inventory code
   */
  public deleteWagonBoxes(mex: string, inventory: string): Observable<{}>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.delete<{}>(`/wagons/boxes`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('deleteWagonBoxes', null))
    );
  }

  /**
   * delete  wagon box type records
   * @param wagonId wagon id
   * @param inventory inventory code
   */
  public deleteWagonBoxTypes(mex: string, inventory: string): Observable<{}>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.delete<{}>(`/wagons/box-types`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('deleteWagonBoxTypes', null))
    );
  }

  /**
   * delete wagon axle records
   * @param wagonId wagon id
   * @param inventory inventory code
   */
  public deleteWagonAxles(mex: string, inventory: string): Observable<{}>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.delete<{}>(`/wagons/axles`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('deleteWagonAxles', null))
    );
  }

  /**
   * delete  wagon axle type records
   * @param wagonId wagon id
   * @param inventory inventory code
   */
  public deleteWagonAxleTypes(mex: string, inventory: string): Observable<{}>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.delete<{}>(`/wagons/axle-types`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('deleteWagonAxleTypes', null))
    );
  }

  /**
   * delete wagon wheel records
   * @param wagonId wagon id
   * @param inventory inventory code
   */
  public deleteWagonWheels(mex: string, inventory: string): Observable<{}>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.delete<{}>(`/wagons/wheels`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('deleteWagonWheels', null))
    );
  }

  /**
   * delete  wagon wheel type records
   * @param wagonId wagon id
   * @param inventory inventory code
   */
  public deleteWagonWheelTypes(mex: string, inventory: string): Observable<{}>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.delete<{}>(`/wagons/wheel-types`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('deleteWagonWheelTypes', null))
    );
  }

  /**
   * create wagon bogie record
   * @param element wagon bogie datas
   */
  public createWagonBogie(element: WagonBogie): Observable<ApiResponse<WagonBogie>>  {
    return this.api.post<ApiResponse<WagonBogie>>(`/wagons/bogies`, element)
    .pipe(
      catchError(this.handleError<any>('createWagonBogie', null))
    );
  }

  /**
   * create wagon bogie type record
   * @param element wagon bogie typedatas
   */
  public createWagonBogieType(element: WagonBogieType): Observable<ApiResponse<WagonBogieType>>  {
    return this.api.post<ApiResponse<WagonBogieType>>(`/wagons/bogie-types`, element)
    .pipe(
      catchError(this.handleError<any>('createWagonBogieType', null))
    );
  }

  /**
   * create wagon box record
   * @param element wagon box datas
   */
  public createWagonBox(element: WagonBox): Observable<ApiResponse<WagonBox>>  {
    return this.api.post<ApiResponse<WagonBox>>(`/wagons/boxes`, element)
    .pipe(
      catchError(this.handleError<any>('createWagonBox', null))
    );
  }

  /**
   * create wagon box type record
   * @param element wagon box type datas
   */
  public createWagonBoxType(element: WagonBoxType): Observable<ApiResponse<WagonBoxType>>  {
    return this.api.post<ApiResponse<WagonBoxType>>(`/wagons/box-types`, element)
    .pipe(
      catchError(this.handleError<any>('createWagonBoxType', null))
    );
  }

  /**
   * create wagon axle record
   * @param element wagon axle datas
   */
  public createWagonAxle(element: WagonAxle): Observable<ApiResponse<WagonAxle>>  {
    return this.api.post<ApiResponse<WagonAxle>>(`/wagons/axles`, element)
    .pipe(
      catchError(this.handleError<any>('createWagonAxle', null))
    );
  }

  /**
   * Save list wagon axle record
   * @param element wagon axle datas
   */
  public createListWagonAxle(element: WagonAxle[]): Observable<ApiResponse<WagonAxle[]>>  {
    return this.api.post<ApiResponse<WagonAxle[]>>(`/wagons/list-axles`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonAxle', null))
    );
  }

  /**
   * create wagon axle type record
   * @param element wagon axle type  datas
   */
  public createWagonAxleType(element: WagonAxleType): Observable<ApiResponse<WagonAxleType>>  {
    return this.api.post<ApiResponse<WagonAxleType>>(`/wagons/axle-types`, element)
    .pipe(
      catchError(this.handleError<any>('createWagonAxleType', null))
    );
  }

  /**
   * create wagon wheel record
   * @param element wagon wheel datas
   */
  public createWagonWheel(element: WagonWheel): Observable<ApiResponse<WagonWheel>>  {
    return this.api.post<ApiResponse<WagonWheel>>(`/wagons/wheels`, element)
    .pipe(
      catchError(this.handleError<any>('createWagonWheel', null))
    );
  }

  /**
   * create wagon wheel type record
   * @param element wagon wheel type  datas
   */
  public createWagonWheelType(element: WagonWheelType): Observable<ApiResponse<WagonWheelType>>  {
    return this.api.post<ApiResponse<WagonWheelType>>(`/wagons/wheel-types`, element)
    .pipe(
      catchError(this.handleError<any>('createWagonWheelType', null))
    );
  }

  /**
   * Finds wagon axle records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonsAxle(mex: string, inventory: string): Observable<ApiResponse<WagonAxle[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonAxle[]>>(`/wagons/wagons-axle`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonsAxle', null))
    );
  }

  /**
   * Finds wagon axle type records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonsAxleType(mex: string, inventory: string): Observable<ApiResponse<WagonAxleType[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonAxleType[]>>(`/wagons/wagons-axle-type`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonsAxleType', null))
    );
  }

  /**
   * Create List wagon axle type record
   * @param element wagon axle type  datas
   */
  public createListWagonAxleType(element: WagonAxleType[]): Observable<ApiResponse<WagonAxleType[]>>  {
    return this.api.post<ApiResponse<WagonAxleType[]>>(`/wagons/list-axles-types`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonAxleType', null))
    );
  }

  /**
   * create WagonSuspension type record
   * @param element WagonSuspension type  datas
   */
  public createListWagonSuspension(element: WagonSuspension[]): Observable<ApiResponse<WagonSuspension[]>>  {
    return this.api.post<ApiResponse<WagonSuspension[]>>(`/wagons/list-suspensions`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonSuspension', null))
    );
  }

  /**
   * Finds wagon suspension records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonsSuspension(mex: string, inventory: string): Observable<ApiResponse<WagonSuspension[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonSuspension[]>>(`/wagons/wagons-suspension`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonsWagonSuspension', null))
    );
  }

  /**
   * create Wagon Bogie type record
   * @param element WagonBogie type  datas
   */
  public createListWagonBogie(element: WagonBogie[]): Observable<ApiResponse<WagonBogie[]>>  {
    return this.api.post<ApiResponse<WagonBogie[]>>(`/wagons/list-bogies`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonBogie', null))
    );
  }
  /**
   * Finds wagon bogie records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonsBogie(mex: string, inventory: string): Observable<ApiResponse<WagonBogie[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonBogie[]>>(`/wagons/wagons-bogie`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonsBogie', null))
    );
  }

  /**
   * create Wagon Bogie Type type record
   * @param element WagonBogieType type  datas
   */
  public createListWagonBogieType(element: WagonBogieType[]): Observable<ApiResponse<WagonBogieType[]>>  {
    return this.api.post<ApiResponse<WagonBogieType[]>>(`/wagons/list-bogies-type`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonBogieType', null))
    );
  }

  /**
   * Finds wagon box type records
   * @param element wagon bogie datas
   */
  public getWagonsBogieType(mex: string, inventory: string): Observable<ApiResponse<WagonBogieType[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonBogieType[]>>(`/wagons/wagons-bogie-type`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonsBogieType', null))
    );
  }

  /**
   * create Wagon Box type record
   * @param element WagonBox type  datas
   */
  public createListWagonBox(element: WagonBox[]): Observable<ApiResponse<WagonBox[]>>  {
    return this.api.post<ApiResponse<WagonBox[]>>(`/wagons/list-box`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonBox', null))
    );
  }


  /**
   * Finds wagon box records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonsBox(mex: string, inventory: string): Observable<ApiResponse<WagonBox[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonBox[]>>(`/wagons/wagons-box`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonsBox', null))
    );
  }

  /**
   * create Wagon Box Type type record
   * @param element WagonBoxType type  datas
   */
  public createListWagonBoxType(element: WagonBoxType[]): Observable<ApiResponse<WagonBoxType[]>>  {
    return this.api.post<ApiResponse<WagonBoxType[]>>(`/wagons/list-box-type`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonBoxType', null))
    );
  }
  /**
   * Finds wagon box type records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonsBoxType(mex: string, inventory: string): Observable<ApiResponse<WagonBoxType[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonBoxType[]>>(`/wagons/wagons-box-type`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonsBoxType', null))
    );
  }

  /**
   * create wagons brake main organ type record
   * @param element WagonBrakeMainOrgan type  datas
   */
  public createListWagonBrakeMainOrgan(element: WagonBrakeMainOrgan[]): Observable<ApiResponse<WagonBrakeMainOrgan[]>>  {
    return this.api.post<ApiResponse<WagonBrakeMainOrgan[]>>(`/wagons/list-brake-main-organ`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonBrakeMainOrgan', null))
    );
  }

  /**
   * Finds wagon wagons brake main organ records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonBrakeMainOrgan(mex: string, inventory: string): Observable<ApiResponse<WagonBrakeMainOrgan[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonBrakeMainOrgan[]>>(`/wagons/wagons-brake-main-organ`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonBrakeMainOrgan', null))
    );
  }

  /**
   * create the list of Wagon regulator record
   * @param element WagonBrakeMainOrgan type  datas
   */
  public createListWagonRegulator(element: WagonRegulator[]): Observable<ApiResponse<WagonRegulator[]>>  {
    return this.api.post<ApiResponse<WagonRegulator[]>>(`/wagons/list-regulator`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonRegulator', null))
    );
  }

  /**
   * Finds wagon regulator records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonRegulator(mex: string, inventory: string): Observable<ApiResponse<WagonRegulator[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonRegulator[]>>(`/wagons/wagons-regulator`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonRegulator', null))
    );
  }

  /**
   * create wagons brake main organ type record
   * @param element WagonBrakeMainOrgan type  datas
   */
  public createListWagonBrakeCylinder(element: WagonBrakeCylinder[]): Observable<ApiResponse<WagonBrakeCylinder[]>>  {
    return this.api.post<ApiResponse<WagonBrakeCylinder[]>>(`/wagons/list-brake-cylinder`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonBrakeCylinder', null))
    );
  }

  /**
   * Finds wagon wagons brake main organ records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonBrakeCylinder(mex: string, inventory: string): Observable<ApiResponse<WagonBrakeCylinder[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonBrakeCylinder[]>>(`/wagons/wagons-brake-cylinder`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonBrakeCylinder', null))
    );
  }

  /**
   * create wagons frame end type record
   * @param element WagonFrameEnd type  datas
   */
  public createListWagonFrameEnd(element: WagonFrameEnd[]): Observable<ApiResponse<WagonFrameEnd[]>>  {
    return this.api.post<ApiResponse<WagonFrameEnd[]>>(`/wagons/list-frame-end`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonFrameEnd', null))
    );
  }

  /**
   * Finds wagons frame end records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonFrameEnd(mex: string, inventory: string): Observable<ApiResponse<WagonFrameEnd[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonFrameEnd[]>>(`/wagons/wagons-frame-end`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonFrameEnd', null))
    );
  }

  /**
   * create wagons wheel type record
   * @param element WagonWheel type  datas
   */
  public createListWagonWheel(element: WagonWheel[]): Observable<ApiResponse<WagonWheel[]>>  {
    return this.api.post<ApiResponse<WagonWheel[]>>(`/wagons/list-wheel`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonWheel', null))
    );
  }

  /**
   * Finds wagons wheel records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonWheel(mex: string, inventory: string): Observable<ApiResponse<WagonWheel[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonWheel[]>>(`/wagons/wagons-wheel`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonWheel', null))
    );
  }

  /**
   * create wagons wheel type type record
   * @param element WagonWheelType type  datas
   */
  public createListWagonWheelType(element: WagonWheelType[]): Observable<ApiResponse<WagonWheelType[]>>  {
    return this.api.post<ApiResponse<WagonWheelType[]>>(`/wagons/list-wheel-type`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonWheelType', null))
    );
  }
  /**
   * Finds wagons wheel type records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonWheelType(mex: string, inventory: string): Observable<ApiResponse<WagonWheelType[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonWheelType[]>>(`/wagons/wagons-wheel-type`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonWheelType', null))
    );
  }

  /**
   * create wagons draw spring type record
   * @param element WagonDrawSpring type  datas
   */
  public createListWagonDrawSpring(element: WagonDrawSpring[]): Observable<ApiResponse<WagonDrawSpring[]>>  {
    return this.api.post<ApiResponse<WagonDrawSpring[]>>(`/wagons/list-draw-spring`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonDrawSpring', null))
    );
  }

  /**
   * Finds wagons draw spring records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonDrawSpring(mex: string, inventory: string): Observable<ApiResponse<WagonDrawSpring[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonDrawSpring[]>>(`/wagons/wagons-draw-spring`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonDrawSpring', null))
    );
  }

  /**
   * create wagons draw hook type record
   * @param element WagonDrawHook type  datas
   */
  public createListWagonDrawHook(element: WagonDrawHook[]): Observable<ApiResponse<WagonDrawHook[]>>  {
    return this.api.post<ApiResponse<WagonDrawHook[]>>(`/wagons/list-draw-hook`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonDrawHook', null))
    );
  }

  /**
   * Finds wagons draw hook records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonDrawHook(mex: string, inventory: string): Observable<ApiResponse<WagonDrawHook[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonDrawHook[]>>(`/wagons/wagons-draw-hook`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonDrawHook', null))
    );
  }

  /**
   * create wagons draw hook type record
   * @param element WagonBumpOrgan type  datas
   */
  public createListWagonBumpOrgan(element: WagonBumpOrgan[]): Observable<ApiResponse<WagonBumpOrgan[]>>  {
    return this.api.post<ApiResponse<WagonBumpOrgan[]>>(`/wagons/list-bump-organ`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonBumpOrgan', null))
    );
  }

  /**
   * Finds wagons draw hook records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonBumpOrgan(mex: string, inventory: string): Observable<ApiResponse<WagonBumpOrgan[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonBumpOrgan[]>>(`/wagons/wagons-bump-organ`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonBumpOrgan', null))
    );
  }

  /**
   * create wagons brake particularity type record
   * @param element WagonBrakeParticularity type  datas
   */
  public createListWagonBrakeParticularity(element: WagonBrakeParticularity[]): Observable<ApiResponse<WagonBrakeParticularity[]>>  {
    return this.api.post<ApiResponse<WagonBrakeParticularity[]>>(`/wagons/list-brake-particularity`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonBrakeParticularity', null))
    );
  }

  /**
   * Finds wagons brake particularity records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonBrakeParticularity(mex: string, inventory: string): Observable<ApiResponse<WagonBrakeParticularity[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonBrakeParticularity[]>>(`/wagons/wagons-brake-particularity`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonBrakeParticularity', null))
    );
  }

  /**
   * create wagons empty loaded device type record
   * @param element WagonEmptyLoadedDevice type  datas
   */
  public createListWagonEmptyLoadedDevice(element: WagonEmptyLoadedDevice[]): Observable<ApiResponse<WagonEmptyLoadedDevice[]>>  {
    return this.api.post<ApiResponse<WagonEmptyLoadedDevice[]>>(`/wagons/list-empty-loaded-device`, element)
    .pipe(
      catchError(this.handleError<any>('createListWagonEmptyLoadedDevice', null))
    );
  }

  /**
   * Finds wagons empty loaded device records
   * @param mex the wagon's mex
   * @param inventory the wagon's inventory
   */
  public getWagonEmptyLoadedDevice(mex: string, inventory: string): Observable<ApiResponse<WagonEmptyLoadedDevice[]>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.get<ApiResponse<WagonEmptyLoadedDevice[]>>(`/wagons/wagons-empty-loaded-device`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonEmptyLoadedDevice', null))
    );
  }

  /**
   * create wagons technical data type record
   * @param element WagonTechnicalData type  datas
   */
  public createWagonTechnicalData(element: WagonTechnicalData): Observable<ApiResponse<WagonTechnicalData>>  {
    return this.api.post<ApiResponse<WagonTechnicalData>>(`/wagons/technical-data`, element)
    .pipe(
      catchError(this.handleError<any>('createWagonTechnicalData', null))
    );
  }

  /**
   * check if opened wagon igloo exists with given mex and workshop
   * @param mex the wagon's mex
   * @param workshop workshop code
   * @returns encapsulated boolean as `Observable`
   */
  public getOpenedIwagonExists(mex: string, workshop: string): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('workshop', `${workshop}`);
    return this.api.get<ApiResponse<boolean>>(`/wagons/igloo/exists`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getOpenedIwagonExists', {data: null}))
    );
  }

  /**
   * get opened wagon igloo with given mex and dealId
   * @param mex the wagon's mex
   * @param dealId deal id
   * @returns encapsulated igloo wagon data as `Observable`
   */
  getOpenedIwagonByMexDealId(mex: string, dealId: number): Observable<ApiResponse<WagonProduction>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('dealId', `${dealId}`);
    return this.api.get<ApiResponse<WagonProduction>>(`/wagons/igloo`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getOpenedIwagonByMexDealId', {data: null}))
    );
  }

  /**
   * Check if the wagon is already taken in hand in the multiple reduced update
   * @param id the wagon's mex
   * @returns encapsulated boolean as `Observable`
   */
  public getWagonOpenedForReducedUpdate(id: number): Observable<ApiResponse<WagonUpdateR>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('id', `${id}`);
    return this.api.get<ApiResponse<WagonUpdateR>>(`/wagons/wagon-updater/reduce-update`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonOpenedForReducedUpdate', {data: null}))
    );
  }

  /**
   *  update wagon igloo exit date
   * @param mex the wagon's mex
   * @param workshop workshop code
   * @param exit updated exit date
   */
  public updateOpenedIwagonExit(mex: string, workshop: string, exit: Date): Observable<Response> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('workshop', `${workshop}`);
    // parameters = parameters.set('exit', `${exit}`);
    return this.api.put<Response>(`/wagons/igloo/exit`, exit, { params: parameters , observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateOpenedIwagonExit', {data: null}))
    );
  }

  /**
   *  Create unknown wagon dans MARGO
   * @param mex the wagon's mex
   */
  public createUnknownWagon(mex: any, maintenanceOperation: string): Observable<ApiResponse<Wagon>> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    parameters = parameters.set('maintenanceOperation', `${maintenanceOperation}`);
    return this.api.post<ApiResponse<Wagon>>(`/wagons/unknown-wagon`, mex, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('createUnknownWagon', null))
    );
  }

  /**
   *  Creer un wagon maj dans MARGO
   * @param wagonUpdate the Wagon update
   */
  public createWagonUpdate(wagonUp: WagonUpdate): Observable<ApiResponse<WagonUpdate>> {
    return this.api.post<ApiResponse<WagonUpdate>>(`/wagons/create-wagon-update`, wagonUp)
    .pipe(
      catchError(this.handleError<any>('createWagonUpdate', null))
    );
  }

  /**
   *  Creer un wagon logique dans MARGO
   * @param logicalWagon the Wagon update
   */
  public createLogicalWagon(logicalWagon: LogicalWagon): Observable<ApiResponse<LogicalWagon>> {
    return this.api.post<ApiResponse<LogicalWagon>>(`/wagons/create-logical-wagon`, logicalWagon)
    .pipe(
      catchError(this.handleError<any>('createLogicalWagon', null))
    );
  }

  /**
   * The system locks the wagon to avoid another opening on the same wagon
   * @param id the wagon's id
   * @returns encapsulated recommissioning datas as `Observable`
   */
  public wagonLocking(id: number, wagon: Wagon): Observable<Response> {
    /*return this.api.put<ApiResponse<Wagon>>(`/wagons/${id}/lock-opened-wagon`)
    .pipe(
      catchError(this.handleError<any>('wagonLocking', {data: null}))
    );*/
    return this.api.put<Response>(`/wagons/${id}/lock-opened-wagon`, wagon, {observe: 'response'});

  }

  /**
   * Download a media linked to an anomaly
   * @param id the wagon's id
   * @param anoId The anomaly's id
   * @param file The media to download
   * @returns file content as an `Observable<Blob>`
   */
  public downloadAnomalyMedia(id: number, anoId: number, file: Media): Observable<Blob> {
    return this.api.get<Blob>(`/wagons/${id}/anomalies/${anoId}/medias/${file.id}`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadAnomalyMedia', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        if (matches !== null && matches[1]) {
          file.filename = matches[1];
        }
        return resp.body;
      })
    );
  }

  /**
   * Download technical datas report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadTechnicalDatasReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/technical-datas/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadTechniaclDatasReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'TCH_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download load table report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadLoadTableReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/technical-datas/loads/pdf`,
    {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadLoadTableReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'CCH_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download Families Restriction report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadFamiliesRestrictionsReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/technical-datas/family/pdf`,
    {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadFamiliesRestrictionsReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'FAR_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download transport last situation report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadLastSituationReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/transport/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadLastSituationReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'DSI_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download overhaul plate/aid maintenance codes report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadOverhaulPlateReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/maintenance/plate/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadOverhaulPlateReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'CCO_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download decision aids report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadDecisionAidsReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/maintenance/decision/pdf`,
    {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadDecisionAidsReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'COA_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download interventions report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadInterventionHistoryReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/interventions/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadInterventionHistoryReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'OPM_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download intervention report
   * @param id the intervention's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadInterventionReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/interventions/${id}/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadInterventionReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'OPMD_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download exploitation report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadExploitationReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/exploitation/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadExploitationReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'HCE_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download mex history report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadMexHistoryReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/exploitation/mexs/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadMexHistoryReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'CEX_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download reforms report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadReformsReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/reforms/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadReformsReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'REF_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download incidents report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadIncidentsReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/wagons/${id}/incidents/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadIncidentsReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'INC_' + id + '.pdf'};
      })
    );
  }

  /**
   * create wagon
   * @param mex wagon mex
   */
  public createWagon(mex: any, trt?: string, status?: string): Observable<ApiResponse<Wagon>>  {
    let parameters: HttpParams = new HttpParams();
    if (trt !== undefined) {
      parameters = parameters.set('treatment', `${trt}`);
    }
    if (status !== undefined) {
      parameters = parameters.set('status', `${status}`);
    }
    return this.api.post<ApiResponse<Wagon>>(`/wagons`, mex, { params: parameters });
  }

  /**
   * Retrieve logical wagon of wagon with given id
   * @param id the wagon's id
   * @returns logical wagon datas as `Observable`
   */
  public getNewlyCreatedLogicalWagons(id: number): Observable<ApiResponse<LogicalWagon>> {
    return this.api.get<ApiResponse<LogicalWagon>>(`/logical-wagons/${id}/logical-wagon`)
    .pipe(
      catchError(this.handleError<any>('getNewlyCreatedLogicalWagons', {data: []}))
    );
  }

  /**
   * Retrieve detailed technical datas of wagon with given mex and inventory
   * @param mex the mex
   * @param inventory the wagon's inventory
   * @returns encapsulated array of detailed technical datas as `Observable`
   */
  public getWagonTechnicalData(mex: string, inventoryCode: string): Observable<ApiResponse<WagonTechnicalData>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventoryCode}`);
    return this.api.get<ApiResponse<WagonTechnicalData>>(`/wagons/technical-data`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonTechnicalData', {data: []}))
    );
  }

  public getLastInventoryHistory(id: number): Observable<ApiResponse<InventoryHistory>> { 
    return this.api.get<ApiResponse<InventoryHistory>>(`/wagons/${id}/last-inventory-history`)
    .pipe(
      catchError(this.handleError<any>('getLastInventoryHistory', {data: []}))
    );
  }
  /**
   * Retrieve detailed technical datas of wagon with given mex and inventory
   * @param wagonId wagon id
   * @param inventory the wagon's inventory
   * @returns encapsulated array of detailed technical datas as `Observable`
   */
   public getWagonTechnicalDataByWagonId(wagonId: number, inventoryCode: string): Observable<ApiResponse<WagonTechnicalData>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wagonId}`);
    parameters = parameters.set('inventory', `${inventoryCode}`);
    return this.api.get<ApiResponse<WagonTechnicalData>>(`/wagons/technical-data-by-wagonid`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonTechnicalData', {data: []}))
    );
  }

  public setCurrentMex(val: string) {
    this.currentMex = val;
  }

  public getCurrentMex(): string {
    return this.currentMex;
  }

  public setCurrentWagon(val: number) {
    this.currentWagon = val;
  }

  public getCurrentWagon(): number {
    return this.currentWagon;
  }

  /** autocomplete mex with autocontrol key */
  completeAutoControl(mex: string): string {
      if (!mex || mex.length !== 11) {
        return mex;
      }
      let ac = 0;
      let clef;
      const ch = '2121212121212';
      for (let i = 0; i < mex.length; i++) {
        let somme = parseInt(mex.substr( i, 1), 10) * parseInt(ch.substr(i, 1), 10);
        if (somme >= 10) {
          somme = somme - 9;
        }
        ac = ac + somme;
      }
      if (( Math.floor(ac / 10) * 10) === ac) {
        clef = 0;
      } else {
        clef = 10 - (ac - (Math.floor(ac / 10) * 10));
      }
      return mex + clef;
  }

  /**
   * Check that mex has an exchange-scheme valid
   * @param mex the wagon's mex
   * @returns encapsulated boolean as `Observable`
   */
  public isValidExchangeScheme(mex: string): Observable<ApiResponse<boolean>> {

    return this.api.get<ApiResponse<boolean>>(`/wagons/${mex}/exchange-scheme`)
    .pipe(
      catchError(this.handleError<any>('isValidExchangeScheme', {data: null}))
    );
  }

  /**
   * Check that a reduced intervention of the RA or REV type was not entered on the same day
   * (on the current date and in the establishment operating on a wagon)
   * @param id the wagon's id
   * @returns encapsulated boolean as `Observable`
   */
   public hasDuplicateInterventionHistory(id: number, dateEntry: string, workshop: string ): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    if (dateEntry !== undefined) {
      parameters = parameters.set('date', `${dateEntry}`);
    }
    if (workshop !== undefined) {
      parameters = parameters.set('workshop', `${workshop}`);
    }
    return this.api.get<ApiResponse<boolean>>(`/wagons/${id}/duplicate-interventions`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('hasDuplicateInterventionHistory', {data: null}))
    );
  }

  /**
   * Control del nuevo MEX
   * @param mex the wagon's mex
   * @param wagonId the wagon's id
   * @returns encapsulated boolean as `Observable`
   */
  public controleNewMex(mex: string, wagonId: number): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('wagonId', `${wagonId}`);
    return this.api.get<ApiResponse<boolean>>(`/wagons/control-new-MEX`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('controleNewMex', {data: null}))
    );
  }


  /**
   * Check that mex has a railway-company valid
   * @param mex the wagon's mex
   * @returns encapsulated boolean as `Observable`
   */
  public isValidRailwayCompany(mex: string): Observable<ApiResponse<boolean>> {

    return this.api.get<ApiResponse<boolean>>(`/wagons/${mex}/railway-company`)
    .pipe(
      catchError(this.handleError<any>('isValidRailwayCompany', {data: null}))
    );
  }

  /**
   * Initialization of the update space
   * @param element initUpdateSpace datas
   */
  public initUpdateSpace(element: InitUpdateSpace): Observable<ApiResponse<LogicalWagon>>  {
    return this.api.post<ApiResponse<LogicalWagon>>(`/wagons/init-update-space`, element)
    .pipe(
      catchError(this.handleError<any>('initUpdateSpace', null))
    );
  }

  /**
   * Modification of the update space
   * @param element initUpdateSpace datas
   */
   public modifyUpdateSpace(element: InitUpdateSpace): Observable<ApiResponse<LogicalWagon>>  {
    return this.api.post<ApiResponse<LogicalWagon>>(`/wagons/modify-update-space`, element)
    .pipe(
      catchError(this.handleError<any>('modifyUpdateSpace', null))
    );
  }

  /**
   * Cancel a wagon opening
   * @param element initUpdateSpace datas
   */
  public cancelOpenWagon(wagonId: number): Observable<ApiResponse<WagonUpdate>>  {
    return this.api.post<ApiResponse<WagonUpdate>>(`/wagons/cancel-open-wagon`, wagonId)
    .pipe(
      catchError(this.handleError<any>('cancelOpenWagon', null))
    );
  }

  
  public getOpenedIwagon(pageable: Pageable): Observable<ApiResponse<any[]>>  {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<any[]>>(`/wagons/prod-opened`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getOpenedIwagon', []))
    );
  }

  
  public getExitedIwagon(date1: Moment, date2: Moment, pageable: Pageable): Observable<ApiResponse<any[]>>  {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    if (date1) {
      parameters = parameters.set('date1', date1.toISOString() );
    }
    if (date2) {
      parameters = parameters.set('date2', date2.toISOString() );
    }
    return this.api.get<ApiResponse<any[]>>(`/wagons/prod-exited`, { params: parameters });
  }

  
  public getExitedIwagonFilet(date1: Moment, date2: Moment, pageable: Pageable): Observable<ApiResponse<any[]>>  {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    if (date1) {
      parameters = parameters.set('date1', date1.toISOString() );
    }
    if (date2) {
      parameters = parameters.set('date2', date2.toISOString() );
    }
    return this.api.get<ApiResponse<any[]>>(`/wagons/prod-exited-filet`, { params: parameters });
  }

  
  public getWagonsUnderContract(pageable: Pageable): Observable<ApiResponse<any[]>>  {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<any[]>>(`/wagons/under-contract`, { params: parameters });
  }

  public getWagonsWithoutContract(pageable: Pageable): Observable<ApiResponse<any[]>>  {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<any[]>>(`/wagons/without-contract`, { params: parameters });
  }

  public getWagonsToMaintain(pageable: Pageable): Observable<ApiResponse<any[]>>  {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<any[]>>(`/wagons/to-maintain`, { params: parameters });
  }

  public saveDealWagonUpdate(value: any): Observable<ApiResponse<DealWagonUpdate>>  {
    return this.api.post<ApiResponse<any>>(`/wagons/deal-wagon-update`, value)
    .pipe(
      catchError(this.handleError<any>('saveDealWagonUpdate', null))
    );
  }
  /*
  public getWagonsEcm(pageable: Pageable): Observable<ApiResponse<any[]>>  {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<any[]>>(`/wagons/ecm`, { params: parameters });
  }*/

  /**
   * Download visit report
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadVisitReport(visit: any): Observable<{blob: Blob, filename: string}> {
    return this.api.post<{blob: Blob, filename: string}>(`/wagons/visits/html`, visit, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadVisitReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'VIS.html'};
      })
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
