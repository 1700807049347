import { MastHeaderConfig } from './components/mast-header/mast-header-config';
import { MastNavConfig } from './components/mast-nav/mast-nav-config';
import { environment } from 'src/environments/environment';

/** Mastheader configuration */
export const APP_HEADER_CONFIG: MastHeaderConfig =	{
  appTitle: environment.name,
  searchService: null,
  notificationServcie: null,
  actionList: [
    { label: 'core.menu.disconnect', routerLink: '/logout', callback: () => {}}
  ]
};
/** Mastnav configuration */
export const APP_NAV_ITEMS: MastNavConfig = {
  top : [
    { routerLink: '/home', title: 'core.mast_nav.home', class: 'icons-29 material-icons', icon: 'home', mustBeLogged: false },
    { routerLink: '/dashboard', title: 'core.mast_nav.home', class: 'icons-29 material-icons', icon: 'home', mustBeLogged: true },
    { title: 'core.mast_nav.consultation.title', class: 'icons-file icons-size-1x5', mustBeLogged: true,
      subItems: [
        { routerLink: '/consultation/wagons', title: 'core.mast_nav.consultation.wagons', hasFunctionalProc: ['TBR01', 'ECON01']},
        { routerLink: '/consultation/expertises', title: 'core.mast_nav.consultation.expertises', hasFunctionalProc: ['TBR01', 'DWE01']},
        { routerLink: '/consultation/axle', title: 'core.mast_nav.consultation.axle', hasFunctionalProc: 'CES01'},
        { routerLink: '/consultation/vpi', title: 'core.mast_nav.consultation.vpi', hasFunctionalProc: ['VPI01']},
        { routerLink: '/consultation/customer-space', title: 'customer_space.title', hasFunctionalProc: ['ECON01']},
        { routerLink: '/consultation/tabco', title: 'core.mast_nav.consultation.tabco', hasFunctionalProc: ['TBR01']}
      ],
      hasOneOfFunctionalProc: ['ECON01', 'DWE01', 'VPI01', 'CES01', 'TBR01']
    },
    { title: 'core.mast_nav.update.title', class: 'icons-29 material-icons', icon: 'create', mustBeLogged: true,
      subItems: [
       { routerLink: '/update/deals', title: 'core.mast_nav.update.deals',
          hasOneOfFunctionalProc: ['PAF001', 'PAF002', 'PAF003', 'PAF004']},
        { routerLink: '/update/recommissioning', title: 'core.mast_nav.update.recommissioning',
          hasOneOfFunctionalProc: ['DWA02', 'DWA03', 'DWA04', 'DWA05']},
        { routerLink: '/update/wagons', title: 'core.mast_nav.update.wagon',
          hasOneOfFunctionalProc: ['MWR01']},
        { routerLink: '/update/expertise', title: 'core.mast_nav.update.expertise',
          hasFunctionalProc: ['TBR01','DWE02']},
        { routerLink: '/update/vpi', title: 'core.mast_nav.update.vpi',
          hasOneOfFunctionalProc: ['VPI02', 'VPI03']},
        { routerLink: '/update/axles', title: 'core.mast_nav.update.axles',
          hasOneOfFunctionalProc: ['CRE01', 'ATE01', 'OTE01', 'MES01', 'REE01']},
        { routerLink: '/update/anomalies', title: 'core.mast_nav.update.anomalies',
          hasOneOfFunctionalProc: ['CIN01', 'CRF01', 'MRF01', 'MIN02']},
        { routerLink: '/update/review-reports', title: 'core.mast_nav.update.review_report',
          hasFunctionalProc: ['SPVR01']},
        { routerLink: '/update/visits', title: 'core.mast_nav.update.visits',
          hasOneOfFunctionalProc: ['DWV01', 'SVE01']},
        { routerLink: '/update/billing', title: 'core.mast_nav.update.billing',
            hasOneOfFunctionalProc: ['FAC001', 'FAC002']},
        { routerLink: '/update/tranches', title: 'core.mast_nav.update.tranches',
          hasOneOfFunctionalProc: ['BAR01', 'BAR02', 'BAR03','TRN01', 'TRN02']}
      ],
      hasOneOfFunctionalProc: ['DWA02', 'DWA03', 'DWA04', 'DWA05', 'PAF001', 'PAF002', 'PAF003', 'PAF004',
                               'VPI02', 'VPI03', 'CRE01', 'ATE01', 'OTE01', 'MES01', 'REE01', 'MWR01',
                               'CIN01', 'CRF01', 'MRF01', 'MIN02', 'SPVR01', 'DWV01', 'SVE01', 'FAC001', 'FAC002', 'BAR01', 'BAR02', 'BAR03','TRN01', 'TRN02']
    },
    { title: 'core.mast_nav.admin.title', class: 'icons-admin icons-size-1x5', mustBeLogged: true, hasService: 'PC',
      subItems: [
        { routerLink: '/administration/scheduling/search/tasks',
          title: 'core.mast_nav.admin.scheduling.tasks_list', hasFunctionalProc: 'PLA01'},
        { routerLink: '/administration/scheduling/restart',
          title: 'core.mast_nav.admin.scheduling.restart_tasks', hasFunctionalProc: 'PLA01'},
        { routerLink: '/administration/cleareances/search/users',
          title: 'core.mast_nav.admin.cleareances.title', hasFunctionalProc: 'HAB01'},
        { routerLink: '/administration/pc-messages',
            title: 'core.mast_nav.admin.pc_messages', hasFunctionalProc: 'MGN05'},
        { routerLink: '/administration/specific-updates',
            title: 'core.mast_nav.admin.specific_updates', hasOneOfFunctionalProc: ['GWT01', 'ETU01', 'ETU02', 'SCE01', 'SCE02', 'SCE03', 'WAP01', 'SCO02', 'SCC01']}
      ]
    },
    { routerLink: '/sitemap', title: 'core.mast_nav.sitemap', class: 'icons-distribution icons-size-1x5' },
    { routerLink: '/about', title: 'core.mast_nav.about', class: 'icons-document icons-size-1x5'}
  ],
  bottom: [
    { routerLink: '/faq', title: 'core.mast_nav.faq', class: 'icons-support icons-size-1x5' }
  ]
};
